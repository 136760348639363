import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn } from "../services/loginSlice";


const Protected = (props) => {
    const {Component} = props
    const isLogin = useSelector(isLoggedIn)
    const location = useLocation()
    
    if(!isLogin) {
        return <Navigate to="/login" state={{ from: location }} />
    }
    
    return <Component />
    
}

export default Protected