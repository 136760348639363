import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";
import { toast } from "react-toastify";

const initialState = {
    status: "Idle",
    resData: '',
    error: '',
    inviteData: '',
    checkInviteEmail:''
}

export const makeTeamSignup = createAsyncThunk(
    'makeTeamSignup/teamsignup',
    async (data) => {
        const headers = {
            "Content-Type": "Application/json",
        }   
        const response = await axios.post(`/user/register`, data, {headers:headers})
        return response.data
    }
)

export const makeTeamInvite = createAsyncThunk(
  'makeTeamInvite/teamsignup',
  async (data) => {
    const hasAuth = JSON.parse(localStorage.getItem('token'))
    const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
    }    
      const response = await axios.post(`/user/invite`, data, {headers:headers})
      return response.data
  }
)

export const checkTeamInviteEmail = createAsyncThunk(
  'checkTeamInviteEmail/teamsignup',
  async (data) => {
    const hasAuth = JSON.parse(localStorage.getItem('token'))
    const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
    }    
      const response = await axios.post(`/user/check-email`, data, {headers:headers})
      return response.data
  }
)


export const teamsignupSlice = createSlice({
    name: 'teamsignup',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
          .addCase(makeTeamSignup.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeTeamSignup.fulfilled, (state, action) => {
            state.status = 'true'
            state.resData = action.payload
            state.error =''
          })
          .addCase(makeTeamSignup.rejected, (state, action) => {
            state.status = 'false'
            state.resData = ''
            state.error = action.error.message
          })
          .addCase(makeTeamInvite.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeTeamInvite.fulfilled, (state, action) => {
            state.status = 'true'
            state.inviteData = action.payload
            state.error =''
            toast.success("Invite sent successfully", { position:"bottom-center"})
          })
          .addCase(makeTeamInvite.rejected, (state, action) => {
            state.status = 'false'
            state.inviteData = ''
            state.error = action.error.message
          })
          .addCase(checkTeamInviteEmail.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(checkTeamInviteEmail.fulfilled, (state, action) => {
            state.status = 'true'
            state.checkInviteEmail = action.payload
            state.error =''
          })
          .addCase(checkTeamInviteEmail.rejected, (state, action) => {
            state.status = 'false'
            state.checkInviteEmail = ''
            state.error = action.error.message
          })
    }
})

export default teamsignupSlice.reducer