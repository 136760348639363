import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    availData : {},
    postAvailData: {},
    changeAvailData: {},
    availStatus: '',
    makeTimeZoneData: ''
}

export const getAvailability = createAsyncThunk(
    'getAvailability/availability',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        } 
        const response = await axios.get(`/user/availability`, {headers:headers})
        return response.data
    }
)

export const updateAvailability = createAsyncThunk(
  'updateAvailability/availability',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      } 
      const response = await axios.post(`/user/availability`, data, {headers:headers})
      return response.data
  }
)

export const changeAvailability = createAsyncThunk(
  'changeAvailability/availability',
  async (fdata) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.post(`/user/change-availability/${fdata.userId}`, fdata.data, {headers:headers})
      return response.data
  }
)

export const getAvailabilityStatus = createAsyncThunk(
  'getAvailabilityStatus/availability',
  async (userId) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.get(`/user/availability-status/${userId}`, {headers:headers})
      return response.data
  }
)

export const makeTimeZone = createAsyncThunk(
  'makeTimeZone/availability',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.post(`/user/time-zone`, data, {headers:headers})
      return response.data
  }
)

export const availibilitySlice = createSlice({
    name:'availability',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
          .addCase(getAvailability.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getAvailability.fulfilled, (state, action) => {
            state.status = 'true'
            state.availData = action.payload
            state.error = ''
          })
          .addCase(getAvailability.rejected, (state, action) => {
            state.status = 'false'
            state.availData = {}
            state.error = action.error.message
          })
          .addCase(updateAvailability.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(updateAvailability.fulfilled, (state, action) => {
            state.status = 'true'
            state.postAvailData = action.payload
            state.error = ''
          })
          .addCase(updateAvailability.rejected, (state, action) => {
            state.status = 'false'
            state.postAvailData = {}
            state.error = action.error.message
          })
          .addCase(getAvailabilityStatus.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getAvailabilityStatus.fulfilled, (state, action) => {
            state.status = 'true'
            state.availStatus = action.payload
            state.error = ''
          })
          .addCase(getAvailabilityStatus.rejected, (state, action) => {
            state.status = 'false'
            state.availStatus = {}
            state.error = action.error.message
          })
          .addCase(changeAvailability.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(changeAvailability.fulfilled, (state, action) => {
            state.status = 'true'
            state.changeAvailData = action.payload
            state.error = ''
          })
          .addCase(changeAvailability.rejected, (state, action) => {
            state.status = 'false'
            state.changeAvailData = {}
            state.error = action.error.message
          })
          .addCase(makeTimeZone.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeTimeZone.fulfilled, (state, action) => {
            state.status = 'true'
            state.makeTimeZoneData = action.payload
            state.error = ''
          })
          .addCase(makeTimeZone.rejected, (state, action) => {
            state.status = 'false'
            state.makeTimeZoneData = {}
            state.error = action.error.message
          })
    }
})

export const getAllAvailability = (state) => state.availability.availData
export const getAvailStatus = (state) => state.availability.availStatus.data
export default availibilitySlice.reducer