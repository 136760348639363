import React, {useState} from "react";
import ContactInfo from "./ContactInfo";
import Shipping from "./Shipping";
import Payment from "./Payment";
import ConfirmOrder from "./ConfirmOrder";

const Checkout = (props) => {
    const [data, setData] = useState({
        email:"",
        country:"",
        first_name:"",
        last_name:"",
        address1:"",
        address2:"",
        city:"",
        province:"",
        zip:"",
        phone:"",
        shippingmethod:"",
        paymentmethod:"",
        cardnumber:"",
        nameoncard:"",
        exdate:"",
        securitycode:"",
        billingaddress:"sameasshipping",
        ba_country:"",
        ba_first_name:"",
        ba_last_name:"",
        ba_address1:"",
        ba_address2:"",
        ba_city:"",
        ba_province:"",
        ba_zip:"",
        ba_phone:"",
    })
    
    const [currentStep, setCurrentStep] = useState(0)

    const [ showConfirm, setShowConfirm ] = useState(false)


      const makeRequest = (formdata) => {
        console.log("form submitted", formdata)
        setShowConfirm(true)
        
      }
    
      const handleNextStep = (newData, final = false) => {
        console.log("newdata", newData)
        setData(prev => ({...prev, ...newData}))
        if(final) { 
          makeRequest(newData)
          return
        }
        setCurrentStep(prev => prev + 1)
      }
    
      const handlePrevStep = (newData) => {
        setData(prev => ({...prev, ...newData}))
        setCurrentStep(prev => prev - 1)
      }

      const handleTwoPrevStep = (newData) => {
        setData(prev => ({...prev, ...newData}))
        setCurrentStep(prev => prev - 2)
      }

      
    
      const steps = [
        <ContactInfo next={handleNextStep} prev={props.showCheckoutHandler} data={data} cusEmail={props.cusEmail} sessionID={props.sessionID} storeName={props.storeName} socketData={props.socketData} subtotal={props.subtotal} />,
        <Shipping next={handleNextStep} prev={handlePrevStep} data={data} socketData={props.socketData} subtotal={props.subtotal} storeName={props.storeName} sessionID={props.sessionID} showCallnotCut={props.showCallnotCut}/>,
        <Payment next={handleNextStep} prev={handlePrevStep} data={data} prevTwo={handleTwoPrevStep} socketData={props.socketData} subtotal={props.subtotal} storeName={props.storeName} sessionID={props.sessionID} />,
      ]

      //console.log("data", data)
    return(
        <>
            <div className="livecartbox rounded-2">
                <div className="border rounded-2 p-3 h-100 position-relative d-flex flex-column overflow-auto">
                    {!showConfirm ? steps[currentStep] : <ConfirmOrder data={data} prev={props.showCheckoutHandler} />}
                </div>
            </div>
        </>
    )
}

export default Checkout