import React, {useState} from "react";
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg'
import { Button, Modal } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import {ReactComponent as DeleteIcon} from '../../assets/images/delete-icon.svg'
import { userDetails } from "../../services/loginSlice";
import { checkTeamInviteEmail, makeTeamInvite } from "../../services/teamsignupSlice";
import { fetchTeamList } from "../../services/teamListSlice";

const InviteTeamModal = ({handleClose, show}) => {

    const [loading, setLoading] = useState(false)
    const [resmsg, setResmsg] = useState(false);
    const [checkEmailmsg, setCheckEmailmsg] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState('');

    const userData = useSelector(userDetails)
    const retailerId = userData && userData.id
    
    const dispatch = useDispatch()
    const resstatus = useSelector(state => state.teamsignup)
    
    const inviteTeamValidationSchema = Yup.object().shape({
      emailFields: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string().email('Invalid email address').required("This field is required")
          })
        )
        .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
    });

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" centered className="selectdatemodal">
                <Button type="button" variant="link" className="position-absolute end-0" onClick={handleClose}><CloseIcon /></Button>
                <Modal.Body className="text-center mt-4">
                    <h2 className="mb-3">Invite your team</h2>
                    <p>Send invitations to team members</p>
                    {
                        resstatus.error.length ? (<div className="p-3 mb-3 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center"> {resstatus.error} </div>) : null
                    }
                    {
                        resmsg ? <div className="p-3 mb-3 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center">{resstatus.inviteData.message}</div> : null
                    }
                    <Formik 
                        initialValues={{emailFields: [{email:''}]}}
                        validationSchema={inviteTeamValidationSchema} 
                        onSubmit={(values) => {                            
                            console.log("values", values)
                            const elist = values.emailFields.map(item => item.email)
                            const formdata = { retailer_id: retailerId, email_id: elist}
                            console.log("elist", formdata)
                            setLoading(true)
                            dispatch(makeTeamInvite(formdata))
                            .then((res)=>{
                              if(res.payload.status === 'success') {
                                  dispatch(fetchTeamList(retailerId))
                                  setLoading(false)
                                  setResmsg(false)
                                  handleClose()   
                              }
                              else {
                                  setLoading(false) 
                                  setResmsg(true)
                              }
                          })
                        }}>
                    {(formprops) => (
                        <Form>
                            <FieldArray
                                name="emailFields"
                                render={arrayHelpers => (
                                  <div className="text-start">
                                    {
                                      formprops.values.emailFields.map((friend, index) => (
                                          <div key={index} className="mb-2 d-flex">
                                            <div className="form-control-cover w-100">                                        
                                            <Field name={`emailFields.${index}.email`} label='First Name' className="form-control" onBlur={
                                              (e) => {
                                                formprops.handleBlur(e)
                                                if((formprops.isValid && formprops.dirty)) {
                                                  console.log("yes")
                                                  dispatch(checkTeamInviteEmail({email_id: formprops.values.emailFields[index].email}))
                                                  .then((res) => {
                                                    if(res.payload.status === 'success') {
                                                      setCheckEmailmsg(false)
                                                    }
                                                    else {
                                                      setCheckEmailmsg(true)
                                                      setCheckedEmail(res.payload.data)
                                                    }                                                  
                                                  })
                                                }
                                              }
                                            } />
                                            {/* <input type="text" className={`form-control`} id="email" name={`emailFields.${index}.email`} /> */}
                                            <label htmlFor="email" className={`form-label ${formprops.values.emailFields[index].email && 'filled'}`}>Email</label>
                                            {formprops.values.emailFields[index].email === checkedEmail && checkEmailmsg ? <div className="text-danger text-start d-block mt-2 w-100 fs-8">Email already exists</div> : null}
                                            
                                            <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                              {formprops.errors &&
                                                formprops.errors.emailFields &&
                                                formprops.errors.emailFields[index] &&
                                                formprops.errors.emailFields[index].email &&
                                                formprops.touched &&
                                                formprops.touched.emailFields &&
                                                formprops.touched.emailFields[index] &&
                                                formprops.touched.emailFields[index].email && (
                                                  <div className="field-error">
                                                    {formprops.errors.emailFields[index].email}
                                                  </div>
                                                )}
                                              </div>
                                          </div>
                                          <div className="ms-2 mt-6">
                                          {formprops.values.emailFields.length !== 1 && (
                                              <Button className="py-0 px-2" tabIndex="-1" variant="link" onClick={() => arrayHelpers.remove(index)}><DeleteIcon role="button" /></Button>
                                          )}
                                          </div>
                                        </div>
                                      ))                                      
                                    }
                                    <div className="ms-sm-0">
                                        <Button disabled={checkEmailmsg || !(formprops.isValid && formprops.dirty)} onClick={() => arrayHelpers.push('')} className="px-0 py-0 fs-8 fw-semibold text-primary" variant="link">+ Add another team member</Button>
                                    </div>
                                    <div className="mt-9">
                                        <Button type="submit" variant="primary" className="w-100 mb-3" size="lg" disabled={checkEmailmsg || !(formprops.isValid && formprops.dirty)}>Send Invite</Button>
                                        <Button type="reset" variant="outline-primary" className="w-100 mb-3" size="lg" onClick={handleClose}>Cancel</Button>
                                    </div>
                                  </div>
                                )}
                              />
                        </Form>
                    )}
                    </Formik>
                </Modal.Body>
            </Modal>
            {
                loading ? <Loader /> : null
            }
        </>
    )
}

export default InviteTeamModal