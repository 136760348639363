import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import noimage from '../../assets/images/noimage.jpg'
import './LiveProDetailCss.scss'
import { Button } from "react-bootstrap";
import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg'
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg'
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../../services/loginSlice";
import { addToCart, removeFromCart } from "../../services/productSlice";

const LiveProductDetail = (props) => {
    var settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
      };
    const [showDesc, setShowDesc] = useState(false)

    const showDescHandler = () => {
        setShowDesc(!showDesc)
    }

    const { proDetailData} = useSelector(state => state.product)
    //console.log("pro Detail", proDetailData)
    const userData = useSelector(userDetails)
    
    const viewData = props.varId ? props.varId && proDetailData.data.product.variants.filter((item) => item.id === props.varId) : []
    //console.log("viewData", viewData)

    const dispatch = useDispatch()
    
    const [nstate, setNstate] = useState({option1:viewData.length ? viewData[0].option1 : proDetailData.data.product.variants[0].option1, option2:viewData.length ? viewData[0].option2 : proDetailData.data.product.variants[0].option2, option3:viewData.length ? viewData[0].option3 : proDetailData.data.product.variants[0].option3})
    //const [nstate, setNstate] = useState({})
    const onChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setNstate({...nstate, [name]:value})        
    }
    //console.log("nstate", nstate, Object.keys(nstate).length)
    const [nqty, setNqty] = useState({qty:props.showAddToCart ? 1 : props.qty})
    const qtyChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setNqty({...nqty, [name]:value})        
    }
    
    //const output = proDetailData.data.product.variants.filter((it) => it.option1 === nstate.option1 && it.option2 === nstate.option2 && it.option3 === nstate.option3 ? nstate.option3 : null)
    // const output = proDetailData.data.product.variants.filter((it) => it.option1 === nstate.option1 && it.option2 === nstate.option2 && (it.option3 && it.option3 != null === nstate.option3))     
    const output = proDetailData.data.product.variants.filter((it) => {
        // if(Object.keys(nstate).length === 2) {
        //   return  it.option1 === nstate.option1 && it.option2 === nstate.option2
        // }
        // if(Object.keys(nstate).length === 3) {
        //     return  it.option1 === nstate.option1 && it.option2 === nstate.option2 && it.option3 === nstate.option3
        // }
        if(nstate['option1'] !== '' &&  nstate['option2'] === '' && nstate['option3'] === '') {
            return  it.option1 === nstate.option1 
        }
        if(nstate['option1'] !== '' &&  nstate['option2'] !== '' && nstate['option3'] === '') {
            return  it.option1 === nstate.option1 && it.option2 === nstate.option2
        }
        if(nstate['option1'] !== '' &&  nstate['option2'] !== '' && nstate['option3'] !== '') {
            return  it.option1 === nstate.option1 && it.option2 === nstate.option2 && it.option3 === nstate.option3
        }
    })
    
    const sessionId = localStorage.getItem('session_id')
    
    let addedPro = localStorage.getItem(`cartItems_${sessionId}`) ? JSON.parse(localStorage.getItem(`cartItems_${sessionId}`)) : []
    
    const addToCartHandler = (pro) => {        
        const sendData = {
            product_id: pro.id, 
            product_name: pro.title, 
            //product_price: pro.variants[0].price, 
            product_price: output.length ? output[0].price : pro.variants[0].price, 
            product_image: pro.image ? pro.image.src : '', 
            session_id: sessionId, 
            variant_id: output.length ? output[0].id : proDetailData.data.product.variants[0].id, 
            handle: proDetailData.data.product.handle, 
            qty:nqty.qty,
            option1:output.length ? output[0].option1 : '',
            option2:output.length ? output[0].option2 : '',
            option3:output.length ? output[0].option3 : '',
        }    
        //console.log("sendData---", sendData)
        dispatch(addToCart(sendData))
        .then(() => {
            props.closeHandler()
        })
        const itemIndex = addedPro.findIndex(
            // (item) => item.product_id === pro.id
            (item) => {
                if(output.length) {
                    return item.variant_id === output[0].id
                }
                else {
                    return item.variant_id === proDetailData.data.product.variants[0].id
                }
                
            }
        )
        if(itemIndex >= 0) {
            addedPro[itemIndex].qty = nqty.qty
        }
        else {
            const tempProduct = { ...sendData}
            addedPro.push(tempProduct)
        }
        localStorage.setItem(`cartItems_${sessionId}`, JSON.stringify(addedPro))
    }

    const removeProductHandler = (pro) => {
        const sendData = {product_id: pro.id, session_id: sessionId, variant_id: output.length ? output[0].id : proDetailData.data.product.variants[0].id }
        dispatch(removeFromCart(sendData))
        const nextCartItems = addedPro.filter(
            // (cartItem) => cartItem.product_id !== pro.id
            (cartItem) => {
                if(output.length) {
                    return cartItem.variant_id !== output[0].id
                }
                else {
                    return cartItem.variant_id !== proDetailData.data.product.variants[0].id
                }
                
            }
        );
        if(nextCartItems.length === 0) {
            localStorage.removeItem(`cartItems_${sessionId}`)
        }
        else {
            addedPro = nextCartItems;
            localStorage.setItem(`cartItems_${sessionId}`, JSON.stringify(addedPro))
        }
        props.closeHandler()
    }
    //console.log("addedPro", addedPro)
    return (
        <>
            <div className="position-relative top-0 bottom-0 start-0 end-0 bg-white rounded-2">                
                <div className="proimgbox rounded-top overflow-hidden">
                    <Slider {...settings}>
                        {
                            proDetailData.data.product.images.length ? (proDetailData.data.product.images.map((item) => {
                                return <div key={item.id}><img src={item.src}  alt="product" /></div>
                            })) : ( <div><img src={noimage}  alt="product" /></div> )
                        }
                    </Slider>
                </div>
                <Button type="button" variant="link" className="position-absolute top-0 end-0" onClick={props.closeHandler}><CloseIcon /></Button>
                <div className="p-3">
                    <div className="fs-7 fw-bold mb-2">{ proDetailData.data.product.title } </div>
                    {/* <div className="fs-8">${proDetailData.data.product.variants[0].price}</div> */}
                    <div className="fs-8">${viewData.length ? viewData[0].price : proDetailData.data.product.variants[0].price}</div>
                    
                    {
                        proDetailData.data.product.options.map((op, index) => {
                            // if(op.name === 'Color' ) {
                            //     return <div key={`cl_${op.id}`} className="mt-3 d-flex">
                            //         {
                            //             op.values.map((item) => {
                            //                 return <label className="colorradio" key={`cl_${item}`}>  
                            //                     <input type="radio" id="" name="option1" value={item} onChange={onChangeHandler} checked={nstate.option1 === item} disabled={props.showAddToCart ? false : true}  />
                            //                     <span id=""   style={{background: item}}> {item} </span>
                            //                 </label>
                            //             })
                            //         }
                            //     </div>
                            // }
                            // if(op.name === 'Size' ) {
                            //     return <div key={`sz_${op.id}`} className="mt-3 d-flex">
                            //         {
                            //             op.values.map((item) => {
                            //                 return <label className="sizeradio" key={`sz_${item}`}>  
                            //                     <input type="radio" id="" name="option2" value={item} onChange={onChangeHandler} checked={nstate.option2 === item} disabled={props.showAddToCart ? false : true} />
                            //                     <span id=""> {item.charAt(0)} </span>
                            //                 </label>
                            //             })
                            //         }
                            //     </div>
                            // }
                            return <div key={`sz_${op.id}`} className="mt-3 d-flex flex-wrap">
                                    <div className="d-flex fs-8 w-100 flex-wrap fw-semibold">{op.name} :</div>
                                    {
                                        op.values.map((item) => {
                                            return <label className={`${op.name === 'Color'? 'colorradio' : 'sizeradio'}`} key={`op_${item}`}>  
                                                <input type="radio" id="" name={`option${index + 1}`} value={item} onChange={onChangeHandler} checked={nstate[`option${index + 1}`] === item} disabled={props.showAddToCart ? false : true} />
                                                <span id="" style={{ background: `${op.name === 'Color'? item : ''}`}}> {item} </span>
                                            </label>
                                        })
                                    }
                                </div>
                        })
                    }
                    <div className="mt-2 qtybox">
                        <span className="fs-7 text-black-50 me-2">Quantity:</span> <input type="number" name="qty" id="qty" className="form-control" onChange={qtyChangeHandler} value={nqty.qty} disabled={props.showAddToCart ? false : true} />
                    </div>
                    <div className="mt-2 mb-2">
                        <Button variant="link" className="text-dark w-100 px-0 d-flex justify-content-between align-items-center" onClick={showDescHandler}>Description <ArrowRight /></Button>
                    </div>
                    <div className="mb-3">
                        {
                            props.showAddToCart ? <Button variant="primary" size="lg" className="w-100" onClick={() => addToCartHandler(proDetailData.data.product)}>Add Product</Button> : <Button variant="outline-primary" size="lg" className="w-100" onClick={() => removeProductHandler(proDetailData.data.product)}>Remove</Button>
                        }
                        
                    </div>
                </div>
            </div>

            <CSSTransition in={showDesc} timeout={300} classNames="slideup" unmountOnExit>
                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-white p-3 rounded-2">
                    <h4 onClick={showDescHandler} role="button"><ArrowLeft className="me-3" /> Description</h4>
                    <hr />
                    <div>
                        {proDetailData.data.product.body_html ? proDetailData.data.product.body_html.replace( /(<([^>]+)>)/ig, '') : (
                            <div>Description is not available</div>
                        )}
                    </div>
                </div>
            </CSSTransition>
        </>
    )
}

export default LiveProductDetail