import React from "react";
import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg';
import Summary from "../component/Summary";
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg'

const Payment = (props) => {
    
    const handleSubmit = (values) => {
        props.next(values, true)
    }
    
    const paymentValidationSchema = Yup.object({
    
    })

    return(
        <>
            <div className="d-flex align-items-center">
                <h4 className="mb-0" onClick={() => props.prev(props.data)} role="button"> <ArrowLeft className="me-2" /> Return to Shipping</h4>
            </div>
            <hr className="mt-2 mt-lg-3 mb-6" />
            <Summary socketData={props.socketData} subtotal={props.subtotal} />
            <hr className="my-6 mt-lg-3" />
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <div className="fs-7 text-black-50">Contact</div>
                    <div><Link to="/" className="fs-7 text-black">{props.data.email}</Link></div>
                </div>
                <div><Button variant="link" className="px-1" onClick={() => props.prevTwo(props.data)}>Change</Button></div>
            </div>
            <div className="d-flex justify-content-between mb-4">
                <div>
                    <div className="fs-7 text-black-50">Ship to</div>
                    <div className="fs-7 text-black">{props.data.address1} <br /> {props.data.city} {props.data.province} {props.data.zip}, {props.data.country}</div>
                </div>
                <div><Button variant="link" className="px-1" onClick={() => props.prevTwo(props.data)}>Change</Button></div>
            </div>
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <div className="fs-7 text-black-50">Method</div>
                    <div className="fs-7 text-black">{props.data.shippingmethod}</div>
                </div>
                <div><Button variant="link" className="px-1" onClick={() => props.prev(props.data)}>Change</Button></div>
            </div>
            <h4 className="fw-normal mb-2">Gift Card or Discount Code</h4>
            <div className="d-flex align-items-center mb-4">
                <div className="col"><input type="text" className="form-control" name="searchpro" id="searchpro" placeholder="Gift card or discount code" /></div>
                <div className="ms-2"><Button variant="light" size="lg" className="py-6 px-8 border-0"><ArrowRight className="whitearrow" /></Button></div>
            </div>
            
            <Formik validationSchema={paymentValidationSchema} initialValues={props.data} onSubmit={handleSubmit}>
            {(formprops) => (
                <Form>
                    <h4 className="fw-normal mb-3">Payment</h4>
                    <div className="mb-2 border rounded-2 p-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-3" type="radio" name="paymentmethod" id="creditcard" value="creditcard" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.paymentmethod === 'creditcard'} />
                            <label className="form-check-label w-100" htmlFor="creditcard">
                                <span className="d-flex flex-column fs-8">
                                    <span>Credit Card </span>
                                    <span className="fs-9 text-black-50">Visa, Mastercard, AMEX, Discover, and more...</span>
                                </span>
                            </label>
                        </div> 
                        <div className="mt-3">
                            <div className="mb-2 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="cardnumber" name="cardnumber" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.cardnumber} />
                                <label htmlFor="cardnumber" className={`form-label ${formprops.values.cardnumber && 'filled'}`}>Card number</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.cardnumber && formprops.errors.cardnumber ? (
                                        <div>{formprops.errors.cardnumber}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-2 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="nameoncard" name="nameoncard" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.nameoncard} />
                                <label htmlFor="nameoncard" className={`form-label ${formprops.values.nameoncard && 'filled'}`}>Name on card</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.nameoncard && formprops.errors.nameoncard ? (
                                        <div>{formprops.errors.nameoncard}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-2 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="exdate" name="exdate" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.exdate} />
                                <label htmlFor="exdate" className={`form-label ${formprops.values.exdate && 'filled'}`}>Expiration date (MM/YY)</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.exdate && formprops.errors.exdate ? (
                                        <div>{formprops.errors.exdate}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-2 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="securitycode" name="securitycode" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.securitycode} />
                                <label htmlFor="securitycode" className={`form-label ${formprops.values.securitycode && 'filled'}`}>Security code</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.securitycode && formprops.errors.securitycode ? (
                                        <div>{formprops.errors.securitycode}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mb-2 border rounded-2 py-6 px-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-2" type="radio" name="paymentmethod" id="paypal" value="paypal" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.paymentmethod === 'paypal'} />
                            <label className="form-check-label w-100" htmlFor="paypal">
                                <span className="d-flex flex-column fs-8">
                                    <span>Paypal</span>
                                </span>
                            </label>
                        </div>                        
                    </div>

                    <h4 className="fw-normal mt-4 mb-3">Billing Address</h4>
                    <div className="mb-2 border rounded-2 py-6 px-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-2" type="radio" name="billingaddress" id="sameasshipping" value="sameasshipping" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.billingaddress === 'sameasshipping'} />
                            <label className="form-check-label w-100" htmlFor="sameasshipping">
                                <span className="d-flex flex-column fs-8">
                                    <span>Same as shipping address</span>
                                </span>
                            </label>
                        </div>                        
                    </div>
                    <div className="mb-2 border rounded-2 py-6 px-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-2" type="radio" name="billingaddress" id="differentaddress" value="differentaddress" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.billingaddress === 'differentaddress'} />
                            <label className="form-check-label w-100" htmlFor="differentaddress">
                                <span className="d-flex flex-column fs-8">
                                    <span>Use a different billing address</span>
                                </span>
                            </label>
                        </div>                        
                    </div>

                    {
                        formprops.values.billingaddress === "differentaddress" ? (
                            <>
                            <div className="mb-3 form-control-cover">                                        
                                <label htmlFor="ba_country" className={`form-label ${formprops.values.ba_country && 'filled'}`}>Country/region</label>
                                <select className="form-select" id="ba_country" name="ba_country" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_country}>
                                    <option value="" disabled hidden></option>
                                    <option value="US">United States</option>
                                    <option value="Canada">Canada</option>

                                </select>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_country && formprops.errors.ba_country ? (
                                        <div>{formprops.errors.ba_country}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="ba_first_name" name="ba_first_name" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_first_name} />
                                <label htmlFor="ba_first_name" className={`form-label ${formprops.values.ba_first_name && 'filled'}`}>First name</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_first_name && formprops.errors.ba_first_name ? (
                                        <div>{formprops.errors.ba_first_name}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="ba_last_name" name="ba_last_name" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_last_name} />
                                <label htmlFor="ba_last_name" className={`form-label ${formprops.values.ba_last_name && 'filled'}`}>Last name</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_last_name && formprops.errors.ba_last_name ? (
                                        <div>{formprops.errors.ba_last_name}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="ba_address1" name="ba_address1" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_address1} />
                                <label htmlFor="ba_address1" className={`form-label ${formprops.values.ba_address1 && 'filled'}`}>Address</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_address1 && formprops.errors.ba_address1 ? (
                                        <div>{formprops.errors.ba_address1}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="ba_address2" name="ba_address2" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_address2} />
                                <label htmlFor="ba_address2" className={`form-label ${formprops.values.ba_address2 && 'filled'}`}>Apartment, suite, etc. (optional)</label>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <label htmlFor="ba_city" className={`form-label ${formprops.values.ba_city && 'filled'}`}>City</label>
                                <select className="form-select" id="ba_city" name="ba_city" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_city}>
                                    <option value="" disabled hidden></option>
                                    <option value="big city">Big City</option>
                                    <option value="New York">New York</option>
                                    <option value="New Jersey">New Jersey</option>
                                    <option value="Ottawa">Ottawa</option>
                                    
                                </select>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_city && formprops.errors.ba_city ? (
                                        <div>{formprops.errors.ba_city}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <label htmlFor="ba_province" className={`form-label filled ${formprops.values.ba_province && 'filled'}`}>State</label>
                                <select className="form-select" id="ba_province" name="ba_province" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_province}>
                                    <option value="" disabled hidden></option>
                                    <option value="CA">CA</option>
                                    <option value="New York">New York</option>
                                    <option value="New Jersey">New Jersey</option>
                                    <option value="Ontario">Ontario</option>
                                    
                                </select>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_province && formprops.errors.ba_province ? (
                                        <div>{formprops.errors.ba_province}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="ba_zip" name="ba_zip" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_zip} />
                                <label htmlFor="ba_zip" className={`form-label ${formprops.values.ba_zip && 'filled'}`}>Zip code</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_zip && formprops.errors.ba_zip ? (
                                        <div>{formprops.errors.ba_zip}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mb-3 form-control-cover">                                        
                                <input type="text" className={`form-control`} id="ba_phone" name="ba_phone" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.ba_phone} />
                                <label htmlFor="ba_phone" className={`form-label ${formprops.values.ba_phone && 'filled'}`}>Phone</label>
                                <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                    {formprops.touched.ba_phone && formprops.errors.ba_phone ? (
                                        <div>{formprops.errors.ba_phone}</div>
                                    ) : null}
                                </div>
                            </div>
                            </> ) : null
                    }

                    <div className="mt-4">
                        <Button variant="primary" size="lg" className="w-100" type="submit">Place Order</Button>
                    </div>                    
                </Form>
            )}
            </Formik>           
        </>
    )
}

export default Payment