import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg';
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Summary from "../component/Summary";
import { useDispatch, useSelector } from "react-redux";
import { getSpCustomerData, makeCreateCustomer } from "../../services/checkoutSlice";
import Smallloader from "../../component/SmallLoader"
import {State, City} from "country-state-city";

const ContactInfo = (props) => {    
    
    const dispatch = useDispatch()
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const contactInfoValidationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required().label('Email'),
        country: Yup.string().required().label('Country'),
        first_name: Yup.string().required().label('First Name'),
        last_name: Yup.string().required().label('Last Name'),
        address1: Yup.string().required().label('Address'),
        city: Yup.string().required().label('City'),
        province: Yup.string().required().label('Province'),
        //zip: Yup.string().required().label('Zip Code'),
        zip: Yup.string()
            .when('country', {
                is: 'US',
                then: Yup.string().required().label('Zip Code')
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .min(5, "Zip Code is not valid")
                    .max(5, "Zip Code is not valid")
            }) 
            .when('country', {
                is: 'CA',
                then: Yup.string().required().label('Zip Code')
                    .min(6, "Zip Code is not valid")
                    .max(6, "Zip Code is not valid")
            }),  
        // phone: Yup.string().required().label('Phone Number').matches(new RegExp('[0-9]{7}'), 'Phone requires a number')
        phone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .min(10, "Phone number is too short")
            .max(12, "Phone enter valid"),
    })

    const { status, spCustomerData } = useSelector(state => state.checkout)
    const [newData, setNewData] = useState(props.data)
    const [checkCus, setCheckCus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [resmsg, setResmsg] = useState(false)
    const resstatus = useSelector(state => state.checkout)
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])

    useEffect(() => {
        const sendData = { email_id:props.cusEmail, store_name: props.storeName}
        setLoading(true)
        dispatch(getSpCustomerData(sendData))
        .then((res)=>{
            if(res.payload.status === 'success' && res.payload.data.length) {
                setCheckCus(true)
                setLoading(false)
                //console.log("res.payload.data.length", res.payload.data.length)                                        
            }
            else {
                setCheckCus(false)
                setLoading(false)
            }
        })
    }, [dispatch])
        
    // useEffect(() => {
    //     if(spCustomerData && spCustomerData.data.length){
    //         setNewData({...newData,
    //             email: spCustomerData.data[0].email ? spCustomerData.data[0].email : '',
    //             country: spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].country_code : '',
    //             first_name:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].first_name : '',
    //             last_name:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].last_name : '',
    //             address1:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].address1 : '',
    //             address2:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].address2 : '',
    //             city:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].city : '',
    //             province:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].province_code : '',
    //             zip:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].zip : '',
    //             phone:spCustomerData.data[0].addresses ? spCustomerData.data[0].addresses[0].phone : '',         
    //            })
    //         setStateList(State.getStatesOfCountry(spCustomerData.data[0].addresses[0].country_code))
    //         setCityList(City.getCitiesOfState(spCustomerData.data[0].addresses[0].country_code, spCustomerData.data[0].addresses[0].province_code))
    //         console.log("stateList", stateList)
    //     }
    //     else {           
    //         setNewData({...newData,
    //             email: props.cusEmail,
    //             country:'', 
    //             first_name:'',
    //             last_name:'',
    //             address1:'',
    //             address2:'',
    //             city:'',
    //             province:'',
    //             zip:'',
    //             phone:'',         
    //            })
    //         setStateList([])
    //         setCityList([])
            
    //     }
    // }, [spCustomerData])

    //console.log("after set", newData)
    //console.log("check cus", checkCus)
    const handleSubmit = (values) => {
        if(!checkCus){ 
            const formData = {
                store_name : props.storeName,
                first_name : values.first_name,
                last_name: values.last_name,
                email:values.email,
                address:values.address1,
                city: values.city,
                state: values.province,
                zip: values.zip,
                country:values.country,
                phone: values.phone
            }
            //console.log("formData", formData)
            setLoading(true)
            dispatch(makeCreateCustomer(formData))
            .then((res) => {
                setLoading(false)
                if(res.payload.status === 'success') {
                    setResmsg(false)
                    props.next(values)
                }                
                else {
                    setResmsg(true)
                    setTimeout(() => {
                        setResmsg(false)
                    },2000)
                }
            })
        }
        else { 
            props.next(values)
        }
    }
    const countryChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setStateList(State.getStatesOfCountry(value))
    }

    const CityChangeHandler = (e, country) => {
        const name = e.target.name;
        const value = e.target.value;
        setCityList(City.getCitiesOfState(country, value))
    }
    
    const formRef = useRef()
    if(status === 'Idle' || status === 'loading') {
        return <div><Smallloader /></div>
    }
    return(
        <>
            
            <div className="d-flex align-items-center">
                <h4 className="mb-0" onClick={props.prev} role="button"> <ArrowLeft className="me-2" /> Return to Cart</h4>
            </div>
            <hr className="mt-2 mt-lg-3 mb-6" />
            <Summary socketData={props.socketData} subtotal={props.subtotal} />
            <hr className="my-6 mt-lg-3" />
            {
            resstatus.error.length ? (<div className="p-3 mb-3 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center fs-7"> {resstatus.error} </div>) : null
            }
            {
            resmsg ? <div className="p-3 mb-3 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center">{resstatus.spCreateCusData.message}</div> : null
            }
            <h4 className="fw-normal mb-3">Contact Information</h4>
            <Formik innerRef={formRef} validationSchema={contactInfoValidationSchema} initialValues={newData} onSubmit={handleSubmit} enableReinitialize={true}>
            {(formprops) => (
                <Form>
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="email" name="email" 
                        onChange={formprops.handleChange} 
                        onBlur={formprops.handleBlur} 
                        value={formprops.values.email} />
                        <label htmlFor="email" className={`form-label ${formprops.values.email && 'filled'}`}>Email</label>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.email && formprops.errors.email ? (
                                <div>{formprops.errors.email}</div>
                            ) : null}
                        </div>
                    </div>
                    <h4 className="fw-normal mb-3">Shipping Address</h4>
                    <div className="mb-3 form-control-cover">                                        
                        <label htmlFor="country" className={`form-label ${formprops.values.country && 'filled'}`}>Country/region</label>
                        <select className="form-select" id="country" name="country" 
                            //onChange={formprops.handleChange} 
                            onBlur={formprops.handleBlur}
                            //onChange={(e) => provienceChangeHandler(e)} 
                            onChange={(e) => {
                                formprops.handleChange(e);
                                countryChangeHandler(e)
                                formprops.setFieldValue("province", "")
                                formprops.setFieldValue("city", "")
                            }} 
                            value={formprops.values.country}>
                            <option value="" disabled hidden></option>
                            <option value="IN">India</option>
                            <option value="US">United States</option>
                            <option value="CA">Canada</option>
                            {/* <option value="India">India</option> */}
                        </select>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.country && formprops.errors.country ? (
                                <div>{formprops.errors.country}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="first_name" name="first_name" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.first_name} />
                        <label htmlFor="first_name" className={`form-label ${formprops.values.first_name && 'filled'}`}>First name</label>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.first_name && formprops.errors.first_name ? (
                                <div>{formprops.errors.first_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="last_name" name="last_name" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.last_name} />
                        <label htmlFor="last_name" className={`form-label ${formprops.values.last_name && 'filled'}`}>Last name</label>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.last_name && formprops.errors.last_name ? (
                                <div>{formprops.errors.last_name}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="address1" name="address1" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.address1} />
                        <label htmlFor="address1" className={`form-label ${formprops.values.address1 && 'filled'}`}>Address</label>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.address1 && formprops.errors.address1 ? (
                                <div>{formprops.errors.address1}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="address2" name="address2" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.address2} />
                        <label htmlFor="address2" className={`form-label ${formprops.values.address2 && 'filled'}`}>Apartment, suite, etc. (optional)</label>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <label htmlFor="city" className={`form-label ${formprops.values.city && 'filled'}`}>City</label>
                        <input type="text" className={`form-control`} id="city" name="city" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.city} />
                        {/* <select className="form-select" id="city" name="city" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.city}>
                            <option value="" disabled hidden></option>
                            {
                                cityList?.map((item, index) => {
                                    return <option key={index} value={item.name}>{item.name}</option>
                                })
                            }
                        </select> */}
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.city && formprops.errors.city ? (
                                <div>{formprops.errors.city}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <label htmlFor="province" className={`form-label ${formprops.values.province && 'filled'}`}>State</label>
                        <select className="form-select" id="province" name="province" 
                            //onChange={formprops.handleChange} 
                            onChange={(e) => {
                                formprops.handleChange(e);
                                CityChangeHandler(e, formprops.values.country)
                                formprops.setFieldValue("city", "")
                            }} 
                            onBlur={formprops.handleBlur} value={formprops.values.province}>
                            <option value="" disabled hidden></option>
                            {
                                stateList?.map((item, index) => {
                                    return <option key={index} value={item.isoCode}>{item.name}</option>
                                })
                            }
                            {/* <option value="" disabled hidden></option>
                            <option value="CA">CA</option>
                            <option value="New York">New York</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Gujarat">Gujarat</option> */}
                        </select>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.province && formprops.errors.province ? (
                                <div>{formprops.errors.province}</div>
                            ) : null}
                        </div>
                    </div>                    
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="zip" name="zip" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.zip} />
                        <label htmlFor="zip" className={`form-label ${formprops.values.zip && 'filled'}`}>Zip code</label>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.zip && formprops.errors.zip ? (
                                <div>{formprops.errors.zip}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="mb-3 form-control-cover">                                        
                        <input type="text" className={`form-control`} id="phone" name="phone" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.phone} />
                        <label htmlFor="phone" className={`form-label ${formprops.values.phone && 'filled'}`}>Phone</label>
                        <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                            {formprops.touched.phone && formprops.errors.phone ? (
                                <div>{formprops.errors.phone}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="">
                        <Button variant="primary" size="lg" className="w-100" type="submit">Continue to Shipping</Button>
                    </div>
                    
                </Form>
            )}
            </Formik>
            {
                loading ? <Smallloader /> : null
            }
        </>
    )
}

export default ContactInfo