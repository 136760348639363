import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";
import { toast } from "react-toastify";

const initialState = {
    status: 'Idle',
    error: '',    
    updateProfileData: '',
    changePasswordData: '',
    updatePlanData: '',
    getCardData:'',
    updateCardData: '',
    removePhotoData:'',
    billingHistoryData:'',
    deleteAccountData:''
}

export const updateProfile = createAsyncThunk(
    'updateProfile/profile',
    async (fdata) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        "Content-Type": "multipart/form-data",
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/user/edit/${fdata.id}`, fdata.data, {headers:headers})
        return response.data
    }
)

export const changePassword = createAsyncThunk(
    'changePassword/profile',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/user/change-password `, data, {headers:headers})
        return response.data
    }
)

export const updatePlan = createAsyncThunk(
    'updatePlan/profile',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/update-plan`, data, {headers:headers})
        return response.data
    }
)

export const getCardDetails = createAsyncThunk(
    'getCardDetails/profile',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.get(`/getcard`, {headers:headers})
        return response.data
    }
)

export const updateCard = createAsyncThunk(
    'updateCard/profile',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/update-card`, data, {headers:headers})
        return response.data
    }
)

export const removePhoto = createAsyncThunk(
    'removePhoto/profile',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.get(`/user/remove-photo`, {headers:headers})
        return response.data
    }
)

export const removeCard = createAsyncThunk(
    'removeCard/profile',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/remove-card`, data, {headers:headers})
        return response.data
    }
)

export const billingHistory = createAsyncThunk(
    'billingHistory/profile',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.get(`/billing-history`, {headers:headers})
        return response.data
    }
)

export const deleteAccount = createAsyncThunk(
    'deleteAccount/profile',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        } 
        const response = await axios.get(`delete-account`, {headers:headers})
        return response.data
    }
)

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder        
        .addCase(updateProfile.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(updateProfile.fulfilled, (state, action) => {
            state.status = 'true'
            state.updateProfileData = action.payload
            state.error = ''
        })
        .addCase(updateProfile.rejected, (state, action) => {
            state.status = 'false'
            state.updateProfileData = ''
            state.error = action.error.message
        })
        .addCase(changePassword.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(changePassword.fulfilled, (state, action) => {
            state.status = 'true'
            state.changePasswordData = action.payload
            state.error = ''
            toast.success("Password changed successfully", { position:"bottom-center"})
        })
        .addCase(changePassword.rejected, (state, action) => {
            state.status = 'false'
            state.changePasswordData = ''
            state.error = action.error.message
        })
        .addCase(updatePlan.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(updatePlan.fulfilled, (state, action) => {
            state.status = 'true'
            state.updatePlanData = action.payload
            state.error = ''
        })
        .addCase(updatePlan.rejected, (state, action) => {
            state.status = 'false'
            state.updatePlanData = ''
            state.error = action.error.message
        })
        .addCase(getCardDetails.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(getCardDetails.fulfilled, (state, action) => {
            state.status = 'true'
            state.getCardData = action.payload
            state.error = ''
        })
        .addCase(getCardDetails.rejected, (state, action) => {
            state.status = 'false'
            state.getCardData = ''
            state.error = action.error.message
        })
        .addCase(updateCard.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(updateCard.fulfilled, (state, action) => {
            state.status = 'true'
            state.updateCardData = action.payload
            state.error = ''
        })
        .addCase(updateCard.rejected, (state, action) => {
            state.status = 'false'
            state.updateCardData = ''
            state.error = action.error.message
        })
        .addCase(removePhoto.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(removePhoto.fulfilled, (state, action) => {
            state.status = 'true'
            state.removePhotoData = action.payload
            state.error = ''
        })
        .addCase(removePhoto.rejected, (state, action) => {
            state.status = 'false'
            state.removePhotoData = ''
            state.error = action.error.message
        })
        .addCase(removeCard.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(removeCard.fulfilled, (state, action) => {
            state.status = 'true'
            state.error = ''
        })
        .addCase(removeCard.rejected, (state, action) => {
            state.status = 'false'
            state.error = action.error.message
        })
        .addCase(billingHistory.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(billingHistory.fulfilled, (state, action) => {
            state.status = 'true'
            state.billingHistoryData = action.payload
            state.error = ''
        })
        .addCase(billingHistory.rejected, (state, action) => {
            state.status = 'false'
            state.billingHistoryData = ''
            state.error = action.error.message
        })
        .addCase(deleteAccount.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(deleteAccount.fulfilled, (state, action) => {
            state.status = 'true'
            state.deleteAccountData = action.payload
            state.error = ''
        })
        .addCase(deleteAccount.rejected, (state, action) => {
            state.status = 'false'
            state.deleteAccountData = ''
            state.error = action.error.message
        })
    }
})

export default profileSlice.reducer