import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    resStatus: '',
    teamList: '',
    error:'',
    delteamMember: ''
}

export const fetchTeamList = createAsyncThunk(
    'fetchTeamList/teamlist',
    async (id) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
            'Content-Type': 'application/json',
            'Authorization' : `${hasAuth}`
        }   
        const res = await axios.get(`/allusers/${id}`, {headers: headers})
        return res.data
    }
)

export const deleteTeamMember = createAsyncThunk(
    'deleteTeamMember/teamlist',
    async (id) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
            'Content-Type': 'application/json',
            'Authorization' : `${hasAuth}`
        }   
        const res = await axios.get(`/user/delete/${id}`, {headers: headers})
        return res.data
    }
)

export const teamListSlice = createSlice({
    name: 'teamlist',
    initialState,
    reducers:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchTeamList.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(fetchTeamList.fulfilled, (state, action) => {
            state.status = 'true'
            state.resStatus = action.payload.status
            state.teamList = action.payload
            state.error = ''
        })
        .addCase(fetchTeamList.rejected, (state, action) => {
            state.status = 'false'
            state.resStatus = action.payload.status
            state.teamList = ''
            state.error = action.error.message
        })
        .addCase(deleteTeamMember.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(deleteTeamMember.fulfilled, (state, action) => {
            state.status = 'true'
            state.delteamMember = action.payload
            state.error = ''
        })
        .addCase(deleteTeamMember.rejected, (state, action) => {
            state.status = 'false'
            state.delteamMember = ''
            state.error = action.error.message
        })

    }
})

export const getAllTeamList = (state) => state.teamlist.teamList.data
export const getError = (state) => state.teamlist.error
export default teamListSlice.reducer