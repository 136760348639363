import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    checksessionData: '',
    
}

export const checkSession = createAsyncThunk(
    'checkSession/checksession',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/user/check-session/${data.userid}/${data.session_id}`, {headers:headers})
        return response.data
    }
)


export const checksessionSlice = createSlice({
    name: 'checksession',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(checkSession.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(checkSession.fulfilled, (state, action) => {
            state.status = 'true'
            state.checksessionData = action.payload
            state.error = ''
          })
          .addCase(checkSession.rejected, (state, action) => {
            state.status = 'false'
            state.checksessionData = ''
            state.error = action.error.message
          })
    }
})

export default checksessionSlice.reducer