import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    sessionData: '',
    sessionFilterData: '',
    customerData: '',
    sessionDates: ''
}

export const getSessionList = createAsyncThunk(
    'getSessionList/sessionlist',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.post(`/user/sessions`, data, {headers:headers})
        return response.data
    }
)

export const sessionListFilter = createAsyncThunk(
    'sessionListFilter/sessionlist',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/user/session/filters`, {headers:headers})
        return response.data
    }
)

export const customerFilter = createAsyncThunk(
    'customerFilter/sessionlist',
    async (userId) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/user/get-customers/${userId}`, {headers:headers})
        return response.data
    }
)
export const sessionDataDates = createAsyncThunk(
    'sessionDataDates/sessionlist',
    async (userId) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/user/session-data/`, {headers:headers})
        return response.data
    }
)

export const sessionlistSlice = createSlice({
    name:'sessionlist',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getSessionList.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(getSessionList.fulfilled, (state, action) => {
            state.status = 'true'
            state.sessionData = action.payload
            state.error = ''
        })
        .addCase(getSessionList.rejected, (state, action) => {
            state.status = 'false'
            state.sessionData = ''
            state.error = action.error.message
        })
        .addCase(sessionListFilter.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(sessionListFilter.fulfilled, (state, action) => {
            state.status = 'true'
            state.sessionFilterData = action.payload
            state.error = ''
        })
        .addCase(sessionListFilter.rejected, (state, action) => {
            state.status = 'false'
            state.sessionFilterData = ''
            state.error = action.error.message
        })
        .addCase(customerFilter.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(customerFilter.fulfilled, (state, action) => {
            state.status = 'true'
            state.customerData = action.payload
            state.error = ''
        })
        .addCase(customerFilter.rejected, (state, action) => {
            state.status = 'false'
            state.customerData = ''
            state.error = action.error.message
        })
        .addCase(sessionDataDates.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(sessionDataDates.fulfilled, (state, action) => {
            state.status = 'true'
            state.sessionDates = action.payload
            state.error = ''
        })
        .addCase(sessionDataDates.rejected, (state, action) => {
            state.status = 'false'
            state.sessionDates = ''
            state.error = action.error.message
        })
    }
})

export default sessionlistSlice.reducer