import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { endSession } from "../../services/shopperSlice";
import { useSelector } from "react-redux";

const LeaveSession = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const resweburl = useSelector(state => state.checkout.spCheckoutData)
    const webUrl = resweburl && resweburl.data.checkout.web_url

    const finalCheckout = () => {
        //dispatch(endSession({session_id: props.sessionID }))
        //navigate('/shopper/redirectpage')
        //window.location.href = webUrl
        window.open(webUrl, "_blank");
        props.handleClose()
        //localStorage.clear()
    }
    return(
        <>
            <Modal show={props.show} onHide={props.handleClose} backdrop="static" centered className="selectdatemodal">
                <Button type="button" variant="link" className="position-absolute end-0 py-3" onClick={props.handleClose}><CloseIcon /></Button>
                <Modal.Body className="text-center mt-4">
                    <h2 className='mb-3'>Complete Checkout</h2>
                    <p>Please click on the button to complete your checkout.</p>
                    <div className="mt-4">
                        <div className="mb-3"><Button variant="primary" size="lg" className="w-100" onClick={finalCheckout}>Complete Checkout</Button></div>
                        <div className="mb-3"><Button variant="outline-primary" size="lg" className="w-100" onClick={props.handleClose}>Cancel</Button></div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LeaveSession