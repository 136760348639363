import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    customerData: '',
    endSessionData: '',
    rejoinSessionData:'',
}

export const getCustomerData = createAsyncThunk(
    'getCustomerData/shopper',
    async (id) => {
        const response = await axios.get(`get-customer-data/${id}`)
        return response.data
    }
)

export const endSession = createAsyncThunk(
  'endSession/shopper',
  async (data) => {
      const headers = {
          'Content-Type': 'application/json',
      }   
      const res = await axios.post(`/user/end-session`, data, {headers: headers})
      return res.data
  }
)

export const getRejoinSesison = createAsyncThunk(
  'getRejoinSesison/shopper',
  async (id) => {
      const response = await axios.get(`/rejoin-session/${id}`)
      return response.data
  }
)

export const shopperSlice = createSlice({
    name: 'shopper',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(getCustomerData.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getCustomerData.fulfilled, (state, action) => {
            state.status = 'true'
            state.customerData = action.payload
            state.error = ''
          })
          .addCase(getCustomerData.rejected, (state, action) => {
            state.status = 'false'
            state.customerData = ''
            state.error = action.error.message
          })
          .addCase(endSession.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(endSession.fulfilled, (state, action) => {
            state.status = 'true'
            state.endSessionData = action.payload
            state.error = ''
          })
          .addCase(endSession.rejected, (state, action) => {
            state.status = 'false'
            state.endSessionData = ''
            state.error = action.error.message
          })
          .addCase(getRejoinSesison.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getRejoinSesison.fulfilled, (state, action) => {
            state.status = 'true'
            state.rejoinSessionData = action.payload
            state.error = ''
          })
          .addCase(getRejoinSesison.rejected, (state, action) => {
            state.status = 'false'
            state.rejoinSessionData = ''
            state.error = action.error.message
          })
    }
})

export default shopperSlice.reducer