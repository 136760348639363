import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    sessionStatusData: '',
    startSessionRes: '',
    addCustomerData: '',
    autocencelres:'',
    sessioncancelinsideres:'',
}

export const sessionStatus = createAsyncThunk(
    'sessionStatus/session',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.post(`/user/session-status`, data, {headers:headers})
        return response.data
    }
)

export const autosessioncancel = createAsyncThunk(
  'autosessioncancel/session',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.post(`/user/auto-session-cancel`, data, {headers:headers})
      return response.data
  }
)

export const sessioncancelinside = createAsyncThunk(
  'sessioncancelinside/session',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.post(`/user/session-cancel-inside`, data, {headers:headers})
      return response.data
  }
)

export const startSession = createAsyncThunk(
  'startSession/session',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.post(`/user/session-link`, data, {headers:headers})
      return response.data
  }
)

export const addCustomer = createAsyncThunk(
  'addCustomer/session',
  async (data) => {
      const response = await axios.post(`/add-customer`, data)
      return response.data
  }
)

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(sessionStatus.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(sessionStatus.fulfilled, (state, action) => {
            state.status = 'true'
            state.sessionStatusData = action.payload
            state.error = ''
          })
          .addCase(sessionStatus.rejected, (state, action) => {
            state.status = 'false'
            state.sessionStatusData = ''
            state.error = action.error.message
          })
          .addCase(startSession.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(startSession.fulfilled, (state, action) => {
            state.status = 'true'
            state.startSessionRes = action.payload
            state.error = ''
          })
          .addCase(startSession.rejected, (state, action) => {
            state.status = 'false'
            state.startSessionRes = ''
            state.error = action.error.message
          })
          .addCase(addCustomer.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(addCustomer.fulfilled, (state, action) => {
            state.status = 'true'
            state.addCustomerData = action.payload
            state.error = ''
          })
          .addCase(addCustomer.rejected, (state, action) => {
            state.status = 'false'
            state.addCustomerData = ''
            state.error = action.error.message
          })
          .addCase(autosessioncancel.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(autosessioncancel.fulfilled, (state, action) => {
            state.status = 'true'
            state.autocencelres = action.payload
            state.error = ''
          })
          .addCase(autosessioncancel.rejected, (state, action) => {
            state.status = 'false'
            state.autocencelres = ''
            state.error = action.error.message
          })
          .addCase(sessioncancelinside.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(sessioncancelinside.fulfilled, (state, action) => {
            state.status = 'true'
            state.sessioncancelinside = action.payload
            state.error = ''
          })
          .addCase(sessioncancelinside.rejected, (state, action) => {
            state.status = 'false'
            state.sessioncancelinside = ''
            state.error = action.error.message
          })
    }
})

export default sessionSlice.reducer