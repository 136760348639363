import React, {useState} from "react";
import LiveProductListItem from "../../component/Product/LiveProductListItem";
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg'
import { CSSTransition } from 'react-transition-group';
import { Button } from "react-bootstrap";

const Summary = (props) => {

    const [showSummary, setShowSummary] = useState(false)
    const summaryHandler = () => {
        setShowSummary(!showSummary)
    }
    
    return(
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <Button variant="link" className={`fs-8 fw-semibold px-2 ${showSummary ? 'bg-info-light' : ''}`} onClick={summaryHandler}>{ showSummary ? '– Hide summary' : '+ Show summary'}</Button>
                <div className="fs-4 fw-bold">{showSummary ? '' : `$${Math.round(props.subtotal * 100) / 100}`}</div>
                <CSSTransition in={showSummary} timeout={300} classNames="slidedown" unmountOnExit>
                    <div className="w-100">
                        {
                            props.socketData && props.socketData?.map((item) => {
                                return <LiveProductListItem key={item.product_id} id={item.product_id} name={item.product_name} price={item.product_price} imgSrc={item.product_image} qty={item.qty} />
                            })
                            
                        }
                        {/* <LiveProductListItem name="Pentry Midsommer Rose Top" price="$89.95" size="Small" color="Green" /> */}
                        {/* <div className="d-flex align-items-center mb-4">
                            <div className="col"><input type="text" className="form-control" name="searchpro" id="searchpro" placeholder="Gift card or discount code" /></div>
                            <div className="ms-2"><Button variant="light" size="lg" className="py-6 px-8 border-0"><ArrowRight className="whitearrow" /></Button></div>
                        </div> */}
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="fs-7">Subtotal</div>
                            <div className="fs-8 fw-bold">${Math.round(props.subtotal * 100) / 100}</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="fs-7">Shipping</div>
                            <div className="fs-9 text-black-50">Calculated at next step</div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </>
    )
}

export default Summary