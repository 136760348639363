import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    resData: '',
}

export const makeForgotPassword = createAsyncThunk(
    'makeForgotPassword/forgotpassword',
    async (data) => {
        const response = await axios.post(`/forgot-password`, data)
        return response.data
    }
)

export const makeResetPassword = createAsyncThunk(
    'makeResetPassword/login',
    async (data) => {
        const response = await axios.post(`/reset-password`, data)
        return response.data
    }
)

export const passwordSlice = createSlice({
    name: 'forgotpassword',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(makeForgotPassword.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeForgotPassword.fulfilled, (state, action) => {
            state.status = 'true'
            state.resData = action.payload
            state.error = ''
          })
          .addCase(makeForgotPassword.rejected, (state, action) => {
            state.status = 'false'
            state.resData = ''
            state.error = action.error.message
          })
          .addCase(makeResetPassword.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeResetPassword.fulfilled, (state, action) => {
            state.status = 'true'
            state.resData = action.payload
            state.error = ''
          })
          .addCase(makeResetPassword.rejected, (state, action) => {
            state.status = 'false'
            state.resData = ''
            state.error = action.error.message
          })  
    },
})


export default passwordSlice.reducer