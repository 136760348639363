import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    recentSessionData: '',
    dashboardData:'',
}

export const getRecentSession = createAsyncThunk(
    'getRecentSession/dashboard',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.post(`/user/sessions`, data, {headers:headers})
        return response.data
    }
)

export const getDashboard = createAsyncThunk(
  'getDashboard/dashboard',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }
      const response = await axios.post(`/dashboard`, data, {headers:headers})
      return response.data
  }
)

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(getRecentSession.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getRecentSession.fulfilled, (state, action) => {
            state.status = 'true'
            state.recentSessionData = action.payload
            state.error = ''
          })
          .addCase(getRecentSession.rejected, (state, action) => {
            state.status = 'false'
            state.recentSessionData = ''
            state.error = action.error.message
          })
          .addCase(getDashboard.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getDashboard.fulfilled, (state, action) => {
            state.status = 'true'
            state.dashboardData = action.payload
            state.error = ''
          })
          .addCase(getDashboard.rejected, (state, action) => {
            state.status = 'false'
            state.dashboardData = ''
            state.error = action.error.message
          })
    }
})

export default dashboardSlice.reducer