import React from "react";
import noimage from '../../assets/images/noimage.jpg'

const liveProductListItem = (props) => {
    
    return (
        <>
            <div className="my-3 align-items-center d-flex prolistitem cursor-pointer" role="button" onClick={() => props.onClickHandler(props.id, props.variant_id, props.qty)}>
                <div className="imgbox me-2">
                    <img src={props.imgSrc ? props.imgSrc : noimage} alt={props.name} />
                </div>
                <div className="fs-8 fw-bold me-2">
                    {props.name} 
                    { props.option1 ? <span className="d-flex fs-9 fw-normal">
                        {props.option1} {props.option2 ? (`| ${props.option2}`) : ''} {props.option3  ? (`| ${props.option3}`) : ''}
                    </span> : null }
                    {props.qty ? <span className="d-flex fs-9 fw-normal">Qty: {props.qty}</span> : null}
                    
                    {/* <span className="d-flex fs-9 fw-normal">{item.color} | {item.size}</span> */}
                </div>
                <div className="fs-9 fw-bold ms-auto">${props.price}</div>

            </div>
        </>
    )
}

export default liveProductListItem