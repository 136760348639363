import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    token: '',
    storeData : {},
    checkStoreData : '',
}

export const checkstore = createAsyncThunk(
  'checkstore/connectShopify',
  async (data) => {
      const response = await axios.post(`/checkstore`, data)
      return response.data
  }
)

export const checkConnect = createAsyncThunk(
    'checkConnect/connectShopify',
    async (data) => {
        const response = await axios.get(`/install?shop=${data}`)
        return response.data
    }
)

export const connectShopifySlice = createSlice({
    name: 'connectShopify',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(checkConnect.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(checkConnect.fulfilled, (state, action) => {
            state.status = 'true'
            state.storeData = action.payload
            state.error = ''
          })
          .addCase(checkConnect.rejected, (state, action) => {
            state.status = 'false'
            state.storeData = {}
            state.error = action.error.message
          })
          .addCase(checkstore.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(checkstore.fulfilled, (state, action) => {
            state.status = 'true'
            state.checkStoreData = action.payload
            state.error = ''
          })
          .addCase(checkstore.rejected, (state, action) => {
            state.status = 'false'
            state.checkStoreData = ''
            state.error = action.error.message
          })
    }
})

export default connectShopifySlice.reducer