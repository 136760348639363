import {React, useState} from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { ReactComponent as Logo } from '../assets/images/logo.svg'
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import HeaderMain from "./HeaderMain";
import ShowSessionRequest from "./ShowSessionRequest";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedIn, logout, makeLogout } from "../services/loginSlice";
import SelectPlatform from "./Modals/SelectPlatform";

const Header = () => {
    const { pathname } = useLocation()

    const isLogin = useSelector(isLoggedIn)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClickScroll = () => {
        const element = document.getElementById('demo');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const logoutHandler = (e) => {
        e.preventDefault()
        dispatch(makeLogout())
        .then(()=>{
            dispatch(logout())
            navigate('/login')
        })

     }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    if(pathname === '/signup' || pathname === '/planandpayment' || pathname === '/startsession' ||  pathname === '/livesession' || pathname === '/sessionrecap' || pathname === '/reset-password' || pathname === '/shopper/shopperlivesession' || pathname === '/shopper/shoppersessionrecap' || pathname === '/noshopifysignup' || pathname === '/connectshopify' || pathname === '/*' || pathname === '/shopper/redirectpage' || pathname === '/checksessionmail') {
        console.log("if header yes")
        return(
            <>
                <Navbar collapseOnSelect expand="lg">
                    <Container>
                        <Navbar.Brand as={NavLink} to="/" className="col-md-2"><Logo /></Navbar.Brand>
                    </Container>
                </Navbar>
            </>
        )
    }

    if(pathname === '/' || pathname === '/login' || pathname === '/retailerlogin' ||  pathname === '/forgotpassword' ||  pathname === '/privacy' ||  pathname === '/contact') {
        return (
            <>
                <Navbar collapseOnSelect expand="lg">
                    <Container>
                        <Navbar.Brand as={NavLink} to="/"><Logo /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                {/* <Nav.Link as={NavLink} eventKey="1" to="/">Solution</Nav.Link>
                                <Nav.Link as={NavLink} eventKey="2" to="/" onClick={handleClickScroll} >Book Free Demo</Nav.Link> */}
                                <Nav.Link as={NavLink} eventKey="1" to="/contact">Contact</Nav.Link>
                                {
                                    isLogin ? (
                                        <>
                                            <Nav.Link as={NavLink} eventKey="2" to="/dashboard">Dashboard</Nav.Link>
                                            <Nav.Link as={NavLink} eventKey="4" to="/" onClick={logoutHandler}>Logout</Nav.Link>
                                        </>
                                            
                                        ) 
                                    : (
                                        <>
                                        <Nav.Link as={NavLink} eventKey="3" to="/login">Login</Nav.Link>
                                        <div className="topbtn">
                                            {/* <Link as={NavLink} to="/signup" className="btn btn-primary btn-lg border-0">Try it Free</Link> */}
                                            <Link onClick={handleShow} className="btn btn-primary btn-lg border-0">Try it Free</Link>                                            
                                        </div>
                                        </>
                                    )
                                }
                                
                            </Nav>
                            
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <SelectPlatform show={show} handleClose={handleClose} />
            </>
        )
    }
    console.log("else header yes")
    return (
        <>
            <HeaderMain />
            <ShowSessionRequest />
        </>
    )
}

export default Header