import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    token: '',
    accStatusData : '',
    checkAccStatusData: ''
}

export const changeAccStatus = createAsyncThunk(
    'changeAccStatus/changeaccountstatus',
    async (fdata) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.post(`/change-account-status/${fdata.userId}`, fdata.data, {headers:headers})
        return response.data
    }
)

export const checkAccStatus = createAsyncThunk(
  'checkAccStatus/changeaccountstatus',
  async (data) => {
      const headers = {
      'Content-Type': 'application/json',
      }
      const response = await axios.post(`/get-status`, data, {headers:headers})
      return response.data
  }
)

export const changeaccountstatusSlice = createSlice({
    name: 'changeaccountstatus',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(changeAccStatus.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(changeAccStatus.fulfilled, (state, action) => {
            state.status = 'true'
            state.accStatusData = action.payload
            state.error = ''
          })
          .addCase(changeAccStatus.rejected, (state, action) => {
            state.status = 'false'
            state.accStatusData = ''
            state.error = action.error.message
          })
          .addCase(checkAccStatus.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(checkAccStatus.fulfilled, (state, action) => {
            state.status = 'true'
            state.checkAccStatusData = action.payload
            state.error = ''
          })
          .addCase(checkAccStatus.rejected, (state, action) => {
            state.status = 'false'
            state.checkAccStatusData = ''
            state.error = action.error.message
          })
    }
})

export const getAccStatus = (state) => state.changeaccountstatus.checkAccStatusData.data
export default changeaccountstatusSlice.reducer