import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    userData: '',
    inquiryData: '',
    error:''
}

export const makesignup = createAsyncThunk(
    'makesignup/signup',
    async (data) => {
        const res = await axios.post(`/user/register`, data)
        return res.data
    }
)

export const makeinquiry = createAsyncThunk(
    'makeinquiry/signup',
    async (data) => {
        const res = await axios.post(`/inquiry`, data)
        return res.data
    }
)

export const signupSlice = createSlice({
    name:'signup',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(makesignup.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(makesignup.fulfilled, (state, action) => {
            state.status = 'true'
            state.userData = action.payload
            state.error = ''
        })
        .addCase(makesignup.rejected, (state, action) => {
            state.status = 'false'
            state.userData = ''
            state.error = action.error.message
        })
        .addCase(makeinquiry.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(makeinquiry.fulfilled, (state, action) => {
            state.status = 'true'
            state.inquiryData = action.payload
            state.error = ''
        })
        .addCase(makeinquiry.rejected, (state, action) => {
            state.status = 'false'
            state.inquiryData = ''
            state.error = action.error.message
        })
    }
})

export default signupSlice.reducer