import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg'
import io from "socket.io-client";
import { autosessioncancel, sessionStatus } from "../../services/sessionSlice";
import { userDetails } from "../../services/loginSlice";
import { useNavigate } from "react-router-dom";


// const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);
const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`, {
    forceNew: true,
    transports: ["polling"],
 });

const SessionRequest = (props) => {
    const dispatch = useDispatch()
    const [messageReceived, setMessageReceived] = useState("");
    const [elseJoined, setElseJoined] = useState(false);

    const userData = useSelector(userDetails)
    const userId = userData && userData.id
    const navigate = useNavigate()

    const acceptReq = () => {
        dispatch(sessionStatus({team_member_id: userId, status: '1', session_id: props.resdata.id}))
        .then((res) => {
            if(res.payload.status === 'success') {
                navigate('/startsession')
            }
            else {
                setElseJoined(true)
                setTimeout(() => {
                    setElseJoined(false)
                    props.handleClose()
                }, 30000)
            }
        })
        console.log("accreq")
    }

    const declineReq = () => {
        dispatch(sessionStatus({team_member_id: userId, status: '4', session_id: props.resdata.id}))
        .then((res) => {
            if(res.payload.status === 'success') {
                socket.on("receive_message", (data) => {
                    setMessageReceived(data.message);
                });
                socket.on(`send_request_${userId}`, (data) => { console.log(data.data)
                    setMessageReceived(data.data);
                });      
            }

        })
        props.handleClose()
        setElseJoined(false)
    }

    const autodeclineReq = () => {
        dispatch(autosessioncancel({team_member_id: userId, status: '4', session_id: props.resdata.id}))
        // .then((res) => {
        //     if(res.payload.status === 'success') {
        //         socket.on("receive_message", (data) => {
        //             setMessageReceived(data.message);
        //         });
        //         socket.on(`send_request_${userId}`, (data) => { console.log(data.data)
        //             setMessageReceived(data.data);
        //         });      
        //     }

        // })
        props.handleClose()
    }

    useEffect(() => {
        setTimeout(() => {
            autodeclineReq()
        },60000)
    },[props.resdata])

    const declinerequest = () => {
        props.handleClose()
        declineReq()
        setElseJoined(false)
    }
    
    return(
        <>
            <Modal show={props.show} onHide={props.handleClose} backdrop="static" centered className="selectdatemodal">
                <Button type="button" variant="link" className="position-absolute end-0" onClick={declinerequest}><CloseIcon /></Button>
                <Modal.Body className="text-center mt-4">
                    {
                        elseJoined ? (
                            <>
                                <h2 className="mb-3">Session Request</h2>
                                <p>Team member already joined</p>
                            </>
                        ) : (
                            <>
                            <h2 className="mb-3">Session Request</h2>
                            <p>A customer is requesting a live shopping session!</p>
                            <div className="mb-3"><Button variant="primary" size="lg" className="w-100" onClick={acceptReq}>Accept</Button></div>
                            <div className="mb-3"><Button variant="outline-primary" size="lg" className="w-100" onClick={declineReq}>Decline</Button></div>
                            </>
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SessionRequest