import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

const SelectPlatform = (props) => {
    // const platformValidationSchema = Yup.object({
    //     platform:Yup.string().required('You need to select one'),
    //     other_platform: Yup.string().when('platform', {
    //         is: 'Other',
    //         then: Yup.string().required('You need to specify platform')
    //     }),        
    // })

    const navigate = useNavigate()

    return(
        <>
            <Modal show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Button type="button" variant="link" className="position-absolute end-0 py-3" onClick={props.handleClose}><CloseIcon /></Button>
                <Modal.Body className="text-center mt-4">
                    <h2 className='mb-3'>Let’s connect your Shopify account</h2>
                    <p>Connecting your Shopify account will allow you to enable Lively on your Shopify store(s).</p>
                    <div className="mt-5">
                        <p className="mb-3"><a href="https://apps.shopify.com/lively-shop" className='btn btn-primary btn-lg w-75'>Connect Shopify</a></p>
                        <p><Link to="/noshopifysignup" className="btn-link fs-8">Don’t have a Shopify account</Link></p>
                    </div>


                    {/* <h3 className="mb-9">Please Select Your Platform</h3>
                    <Formik 
                        initialValues={{ platform: '', other_platform: '' }}
                        validationSchema={platformValidationSchema} 
                        onSubmit={(values) => {
                            console.log("values", values)
                            if(values.platform === 'Shopify') {
                                props.handleClose()
                                window.location.href = 'https://apps.shopify.com/lively-shop'
                            }
                            else {
                                props.handleClose()
                                navigate('/noshopifysignup')
                            }
                        }}>
                    {(formprops) => (
                        <Form id="foo">
                            <div className="mb-3 form-control-cover">
                                    <label htmlFor="platform" className={`form-label ${formprops.values.platform && 'filled'}`}>Select your platform</label>
                                    <select className="form-select" id="platform" name="platform" value={formprops.values.platform} onChange={formprops.handleChange} onBlur={formprops.handleBlur}>
                                        <option value="" disabled hidden></option>
                                        <option value="Magento">Adobe Commerce (Magento)</option>
                                        <option value="Big Cartel">Big Cartel</option>
                                        <option value="BigCommerce">BigCommerce</option>
                                        <option value="Ecwid">Ecwid</option>
                                        <option value="FastSpring">FastSpring</option>
                                        <option value="nopCommerce">nopCommerce</option>
                                        <option value="OpenCart">OpenCart</option>
                                        <option value="Oracle Commerce">Oracle Commerce</option>
                                        <option value="PrestaShop">PrestaShop</option>
                                        <option value="Salesforce Commerce Cloud">Salesforce Commerce Cloud</option>
                                        <option value="Shift4Shop">Shift4Shop</option>
                                        <option value="Shopify">Shopify</option>
                                        <option value="Squarespace">Squarespace</option>
                                        <option value="Square Online">Square Online</option>
                                        <option value="Volusion">Volusion</option>
                                        <option value="Weebly">Weebly</option>
                                        <option value="Wix">Wix</option>
                                        <option value="WooCommerce">WooCommerce</option>
                                        <option value="Zyro">Zyro</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                        {formprops.touched.platform && formprops.errors.platform ? (
                                            <div>{formprops.errors.platform}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {
                                    formprops.values.platform === 'Other' && (
                                        <div className="mb-3 form-control-cover">
                                            <label htmlFor="other_platform" className={`form-label ${formprops.values.other_platform && 'filled'}`}>Other platform</label>
                                            <input type="text" className={`form-control`} id="other_platform" name="other_platform"  onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.other_platform} />
                                            <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                                                {formprops.touched.other_platform && formprops.errors.other_platform ? (
                                                    <div>{formprops.errors.other_platform}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    )
                                }
                            <div className="mb-3 mt-4">
                                <Button variant="primary" size="lg" className="w-100 border-0" type="submit" form="foo" disabled={formprops.values.platform.length === 0 ? true : false}>Submit</Button>
                            </div>
                            <div className="">
                                <Button variant="outline-primary" className="w-100 mb-3" size="lg" onClick={props.handleClose}>Cancel</Button>
                            </div>
                        </Form>
                    )}
                    </Formik> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectPlatform