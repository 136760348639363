import React from "react";
import loaderimg from '../assets/images/loader.gif'
const loader = () => {
    return(
        <div className="commonloader position-fixed top-0 bottom-0 start-0 end-0 bg-white d-flex justify-content-center align-items-center flex-column">
            <div className="fs-4 text-black-50 position-relative">Please hang tight</div>
            <div className="mt-n4"><img src={loaderimg} alt="loading..." /></div>
        </div>
    )
}

export default loader