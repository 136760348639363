import {React, useState, useRef, useEffect} from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Logo } from '../assets/images/logo.svg'
import { ReactComponent as NotificationIcon } from '../assets/images/notification-icon.svg'
import { ReactComponent as ArrowDownIcon } from '../assets/images/arrow-down.svg'
import { Link, NavLink, useNavigate } from "react-router-dom";
import placeholder from '../assets/images/profile-placeholder.jpg'
import { useDispatch, useSelector } from "react-redux";
import { getuserDetails, isLoggedIn, logout, makeLogout, userDetails } from "../services/loginSlice";
import { changeAvailability, getAvailabilityStatus, getAvailStatus } from "../services/availabilitySlice";
import InviteTeamModal from "./Modals/InviteTeamModal";

const HeaderMain = () => {
    
    const [showProfileMenu, setShowProfileMenu] = useState(false)
    const showProfileMenuHandler = () => {
        setShowProfileMenu(!showProfileMenu)
        //console.log(showProfileMenu)

    }
    const [showNotificationMenu, setShowNotificationMenu] = useState(false)
    const showNotificationHandler = () => {
        setShowNotificationMenu(!showNotificationMenu)
    }
    
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
            
            if (ref.current) {
                setShowProfileMenu(false)
                setShowNotificationMenu(false)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
           document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }
    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logoutHandler = (e) => {
        e.preventDefault()
        dispatch(makeLogout())
        .then(()=>{
            dispatch(logout())
            navigate('/login')
        })

     }

    const isLogin = useSelector(isLoggedIn)

    const userData = useSelector(userDetails)
    //const profileimg = userData && userData.profilepic ? `${process.env.REACT_APP_PROFILE_PIC}${userData.profilepic}` : ''
    const getUserData = useSelector(getuserDetails)
    const profileimg = getUserData && getUserData.profilepic ? `${process.env.REACT_APP_PROFILE_PIC}${getUserData.profilepic}` : ''
    
    useEffect(()=>{
        dispatch(getAvailabilityStatus(userId))
    }, [dispatch])

    const availStatus = useSelector(getAvailStatus)
    const statustemp = availStatus ? availStatus.availability : ''
    const userId = userData && userData.id 

    const [changeavail, setChangeavail] = useState({availability: statustemp})
    useEffect(()=>{
        setChangeavail({availability: statustemp})
    }, [statustemp])
       
    //  console.log("changeavail", changeavail)

    const changeavailHandler = (e) => {
        const target = e.target
        const name = target.name
        const value = target.type === "checkbox" ? target.checked : target.value
        setChangeavail({...changeavail, [name]:value})
        const formdata = {data:{availability: value}, userId: userData && userData.id} 
        //console.log("formdata", formdata.data)
        dispatch(changeAvailability(formdata))
    }

    useEffect(() => {
        if(userData && userData.role === 1) {
            //console.log("getUserData", getUserData)
            // if(getUserData && getUserData.store_name === null) {
            //     navigate('/connectshopify')
            // }
            // if(getUserData && getUserData.shopify_store_accessKey === null) {
            //     navigate('/connectshopify')
            // }
            // else if(getUserData && getUserData.stripe_subsciption_id === null) {    
            //     navigate('/planandpayment')
            // }
        }
        // if(userData && userData.role === 2) {
        //     if(userData && userData.fullname === '') {
        //         navigate('/settings')
        //     }
        // }
    }, [getUserData])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container className="justify-content-normal">
                    <Navbar.Brand as={NavLink} to={`${isLogin ? '/dashboard' : '/'}`} className="col-md-2"><Logo /></Navbar.Brand>
                    <Navbar id="responsive-navbar-nav" className="d-flex flex-fill py-0">
                        <Nav className="d-none d-lg-flex dashmenu">
                            <Nav.Link as={NavLink} to="/dashboard">Overview</Nav.Link>
                            <Nav.Link as={NavLink} to="/sessions">Sessions</Nav.Link>
                            <Nav.Link as={NavLink} to="/availability">Availability</Nav.Link>
                            {userData && userData.role === 2 ? null : <Nav.Link as={NavLink} to="/team">Team</Nav.Link>}
                        </Nav>
                        <div className="ms-auto d-flex align-items-center">
                            <div className="avaswitch mx-2">
                                <input type="checkbox" name="availability" id="availability" value={changeavail.availability} onChange={changeavailHandler} checked={changeavail.availability} />
                                <label htmlFor="availability"></label>
                            </div>
                            {/* <div className="topnotification mx-2" ref={wrapperRef}>
                                { showNotificationMenu ? <div className="backdrop"></div> : null }
                                <button type="button" onClick={showNotificationHandler}><NotificationIcon /></button>
                                <CSSTransition in={showNotificationMenu} timeout={300} classNames="slidedown" unmountOnExit>
                                <div className="notificationmenu">
                                    <div className="d-flex align-items-center border-bottom">
                                        <div className="p-3 fw-bold fs-7">Notifications</div>
                                        <div className="p-3 fs-8"><Link to="/">Mark all as read</Link></div>
                                    </div>
                                    <div className="py-2">
                                        <div className="py-2 px-3 fs-9 fw-bold text-black-50">Today</div>
                                        <ul>
                                            <li className="fs-8">Notification will go here. <span className="fs-9 text-black-50">2 mins ago</span></li>
                                        </ul>
                                    </div>
                                </div>
                                </CSSTransition>
                            </div> */}
                            {/* topnotification end */}
                            <div className="profilepic ms-2" ref={wrapperRef}>
                                { showProfileMenu ? <div className="backdrop" onClick={showProfileMenuHandler}></div> : null }
                                <button onClick={showProfileMenuHandler} type="button">
                                    <span><img src={profileimg ? profileimg : placeholder} alt="Profile" /></span> <ArrowDownIcon />
                                </button>
                                <CSSTransition in={showProfileMenu} timeout={300} classNames="slidedown" unmountOnExit>
                                    <div className="profilemenu">
                                        <ul>
                                            <li><Link to="/dashboard">Overview</Link></li>
                                            <li><Link to="/sessions">Sessions</Link></li>
                                            <li><Link to="/availability">Availability</Link></li>
                                            {userData && userData.role === 2 ? null : <li><Link to="/team">Team</Link></li>}
                                        </ul>
                                        <hr />
                                        <ul>
                                            <li><Link to="/settings">Settings</Link></li>
                                            {/* {userData && userData.role === 2 ? null : <li><Link to="/teamsignup">+ Invite team</Link></li>} */}
                                            {userData && userData.role === 2 ? null : <li><Link onClick={handleShow}>+ Invite team</Link></li>}
                                            <li><Link to="/support">Support</Link></li>
                                            <li><Link to="/" onClick={logoutHandler}>Log out</Link></li>
                                        </ul>
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                        </Navbar>
                </Container>
            </Navbar>
            <InviteTeamModal show={show} handleClose={handleClose} />
        </>
    )
}

export default HeaderMain