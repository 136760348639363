import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    spCustomerData: '',
    spCreateCusData: '',
    spCheckoutData: ''
}

export const getSpCustomerData = createAsyncThunk(
  'getSpCustomerData/checkout',
  async (data) => {
      const headers = {
          'Content-Type': 'application/json',
      }   
      const res = await axios.post(`/customer`, data, {headers: headers})
      return res.data
  }
)

export const makeCreateCustomer = createAsyncThunk(
  'makeCreateCustomer/checkout',
  async (data) => {
      const headers = {
          'Content-Type': 'application/json',
      }   
      const res = await axios.post(`/create-customer`, data, {headers: headers})
      return res.data
  }
)

export const makeCheckout = createAsyncThunk(
  'makeCheckout/checkout',
  async (data) => {
      const headers = {
          'Content-Type': 'application/json',
      }   
      const res = await axios.post(`/checkout`, data, {headers: headers})
      return res.data
  }
)

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
          .addCase(getSpCustomerData.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getSpCustomerData.fulfilled, (state, action) => {
            state.status = 'true'
            state.spCustomerData = action.payload
            state.error = ''
          })
          .addCase(getSpCustomerData.rejected, (state, action) => {
            state.status = 'false'
            state.spCustomerData = ''
            state.error = action.error.message
          })
          .addCase(makeCreateCustomer.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeCreateCustomer.fulfilled, (state, action) => {
            state.status = 'true'
            state.spCreateCusData = action.payload
            state.error = ''
          })
          .addCase(makeCreateCustomer.rejected, (state, action) => {
            state.status = 'false'
            state.spCreateCusData = ''
            state.error = action.error.message
          })
          .addCase(makeCheckout.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeCheckout.fulfilled, (state, action) => {
            state.status = 'true'
            state.spCheckoutData = action.payload
            state.error = ''
          })
          .addCase(makeCheckout.rejected, (state, action) => {
            state.status = 'false'
            state.spCheckoutData = ''
            state.error = action.error.message
          })
    }
})

export default checkoutSlice.reducer