import React, {useEffect, useState} from "react";
import { Button, Container } from "react-bootstrap";
import LiveProductListItem from "../../component/Product/LiveProductListItem";
import { CSSTransition } from 'react-transition-group';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg'
import LiveProductDetail from "../../component/Product/LiveProductDetail";
import Checkout from "./Checkout";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../../services/loginSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { endSession, getCustomerData } from "../../services/shopperSlice";
import { makeCheckout } from "../../services/checkoutSlice";
import { fetchProDetail } from "../../services/productSlice";
import Smallloader from "../../component/SmallLoader";
import LeaveSession from "../../component/Modals/LeaveSession";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import io from "socket.io-client";
// const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);
const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`, {
    forceNew: true,
    transports: ["polling"],
 });

const ShopperLiveSession = () => {
    const [searchParams] = useSearchParams()
    const roomID = searchParams.get("roomID")
    const sessionID = searchParams.get("session_id")
    //console.log("sessionID---", sessionID)

    const dispatch = useDispatch()

    const { status, error, customerData } =  useSelector(state => state.shopper)
    const cusEmail = customerData && customerData.data.customer_data.email_id
    const cusName = cusEmail.substring(0, cusEmail.lastIndexOf("@"))
    const storeName = customerData && customerData.data.user_data.store_name

    useEffect(() => {
        dispatch(getCustomerData(sessionID))
        localStorage.setItem(`session_id`, sessionID)
    }, [dispatch])
    //console.log("customerData-----", customerData)
    const isMobile = window.innerWidth < 992;

    const [showProBox, setShowProBox] = useState(false)

    const showProHandler = () => {
        setShowProBox(!showProBox)
    }

    const [showProDetail, setShowProDetail] = useState(false)

    const [cartqty, setCartqty] = useState()
    const [varId, setVarId] = useState()
    const showProDetailHandler = (id, vid, qty) => {
        const sendData = {store_name:storeName, product_id: id }
        dispatch(fetchProDetail(sendData))
        .then(() => {
            setShowProDetail(true)
            setCartqty(qty)
            setVarId(vid)
        })
    }

    const closeProDetailHandler = () => {
        setShowProDetail(false)
    }

    

    const continueShopHandler = () => {
        setShowCheckout(false)
        localStorage.removeItem(`cartItems_${sessionID}`)
    }

    const userData = useSelector(userDetails)
    const userId = userData && userData.id
    const userName = userData && userData.fullname
    
    let socketData = localStorage.getItem(`cartItems_${sessionID}`) ? JSON.parse(localStorage.getItem(`cartItems_${sessionID}`)) : []
    //const [socketData, setSocketData] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem("cartItems")) : []) 

    const [listItem, setListItem] = useState(socketData)

    const navigate = useNavigate()

    useEffect(() => {
        socket.on(`add_to_cart_${sessionID}`, (data) => { //console.log("addtocart socket", data.data)
            const itemIndex = socketData.findIndex(
                (item) => item.variant_id === data.data.variant_id
            )
            if(itemIndex >= 0) {
                //console.log("if alreeady")
                socketData[itemIndex].qty = data.data.qty
                setListItem(socketData)
            }
            else {
                const tempProduct = { ...data.data}
                socketData.push(tempProduct)
                setListItem(socketData)                
            }
            localStorage.setItem(`cartItems_${sessionID}`, JSON.stringify(socketData))
        });
        socket.on(`remove_from_cart_${sessionID}`, (data) => { //console.log("remove from cart socket", data.data)
            const nextCartItems = socketData.filter(
                (item) => item.variant_id !== data.data.variant_id
            );
            if(nextCartItems.length === 0) {
                setListItem(socketData) 
                localStorage.removeItem(`cartItems_${sessionID}`)
            }
            else {            
                socketData = nextCartItems;
                setListItem(socketData)
                localStorage.setItem(`cartItems_${sessionID}`, JSON.stringify(socketData))
            }
        });
    }, [socket, socketData]);   

    // const removeProductHandler = (pro) => {
    //     console.log("remove", pro)
    //     const nextCartItems = socketData.filter(
    //         (cartItem) => cartItem.product_id !== pro.id
    //     );
    //     console.log("remove next", nextCartItems)
    //     socketData = nextCartItems;
    //     setListItem(socketData)
    //     localStorage.setItem(`cartItems_${sessionID}`, JSON.stringify(socketData))
    //     setShowProDetail(false)
    //     if(nextCartItems.length === 0) { 
    //         localStorage.removeItem(`cartItems_${sessionID}`)
    //     }
    // }
    const [subtotal, setSubtotal] = useState()
    const [totalQty, setTotalQty] = useState()
    useEffect(() => {
        let {total, quantity} = socketData.reduce(
            (cartTotal, cartItem) => {
                const {product_price, qty} = cartItem;
                const itemTotal = product_price * qty;
                
                cartTotal.total += itemTotal;
                cartTotal.quantity += parseInt(qty);
                //console.log("itemTotal", cartTotal.total)
                return cartTotal
            }, {
                total: 0,
                quantity: 0
            }
        );
        setSubtotal(total)
        setTotalQty(quantity)
    }, [socketData])

    //console.log("listItem----", listItem)
    //console.log("socketData----use effect", socketData)
    //console.log("subtotal------------", subtotal)
    
    const [callnotcut, setCallnotcut] = useState(false)
    const showCallnotCut = () => {
        setCallnotcut(true)
        //console.log("showCallnotCut showCallnotCutfunction call")
    }
    
    const [loading, setLoading] = useState(false)
    const [resmsg, setResmsg] = useState(false)
    const [apiresmsg, setApiresmsg] = useState(false)
    const resstatus = useSelector(state => state.checkout)

    console.log("resstatus---", resstatus)

    const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
    
    const [showCheckout, setShowCheckout] = useState(false)

    let line_items = socketData.map((item) => {
        return ({"variant_id" :item.variant_id, "quantity":item.qty})
    })

    const showCheckoutHandler = () => {
        const formData = {
            email:cusEmail,
            store_name:storeName,
            session_id:sessionID,
            line_items : line_items
        }
        console.log("formData", formData)
        setLoading(true)
        showCallnotCut()
        dispatch(makeCheckout(formData))
        .then((res) => {
            console.log("res---", res)
            // if(res && res.error) {
            //     setApiresmsg(true)
            //     setLoading(false)
            //     setTimeout(() => {
            //         setApiresmsg(false)
            //     },5000)
            // }
            if(res.payload.status === 'success') {
                console.log("iffff")
                setResmsg(false)                
                setShow(true)
                // dispatch(endSession({session_id: sessionID }))
                // navigate('/shopper/redirectpage')
                setLoading(false)
                
            } 
            else {
                console.log("elseee")
                setApiresmsg(true)
                setLoading(false)
                setTimeout(() => {
                    setApiresmsg(false)
                },5000) 
            }               
            // else {
            //     console.log("elseee")
            //     setResmsg(true)
            //     setLoading(false)
            //     setTimeout(() => {
            //         setResmsg(false)
            //     },5000)
            // }            
        })
        //setShowCheckout(!showCheckout)
    }

    let myMeeting = async (element) => {
        // generate Kit Token
        const appID = parseInt(process.env.REACT_APP_ZEGO_ID);
        const serverSecret = process.env.REACT_APP_ZEGO_KEY;
         const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  Date.now().toString(),  cusName);   
   
        // Create instance object from Kit Token.
         const zp = ZegoUIKitPrebuilt.create(kitToken);
         // start the call
         zp.joinRoom({
           container: element,
           scenario: {
             mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
           },
           showPreJoinView: false,
           onLeaveRoom: (users) => {
            setTimeout(() => {
                if(callnotcut === false) {
                    dispatch(endSession({session_id: sessionID }))
                    navigate(`/shopper/shoppersessionrecap?roomID=${roomID}&session_id=${sessionID}`)
                }
            },1500)
            // dispatch(endSession({session_id: sessionID }))
            // .then(() => {
            //     navigate(`/shopper/shoppersessionrecap?roomID=${roomID}&session_id=${sessionID}`)
            // })
            },
         });   
     };
    if( error ) {
        return <div className="container text-center mt-5">{error.message}</div>
    }

    if( status === 'Idle' || status === 'loading' ) {
        return <div className="container text-center mt-5">Data Loading session...</div>
    }
    if( customerData && customerData.data.customer_data.session_purchase === true) {
        return <div className="container text-center mt-5">
            <p>Your session is ended . please click to join new session</p>
            <p><a href={`https://${storeName}`} className="btn btn-primary btn-lg mt-8">Click Here</a></p>

        </div>
    }
    return(
        <>
            <Container className="mb-5">
                <div className="livevideocontainer">
                    <div className="videobox">
                        {/* <img src={teammembervideoimg} alt="video" className="img-fluid" /> */}
                        <div className="ratio ratio-16x9">
                            <div className="myCallContainer" ref={myMeeting}></div>
                        </div>
                    </div>
                    {
                        showCheckout ? <Checkout showCallnotCut={showCallnotCut} showCheckoutHandler={showCheckoutHandler} sessionID={sessionID} storeName={storeName} cusEmail={cusEmail} socketData={socketData} subtotal={subtotal} /> : (
                            <CSSTransition in={isMobile ? showProBox : !showProBox} timeout={300} classNames="slideup" unmountOnExit>
                                {
                                    !showProDetail ? (
                                <div className="livecartbox rounded-2">
                                    <div className="border rounded-2 p-3 h-100 position-relative d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mb-0">Your Cart</h4>
                                            <Button type="button" variant="link" className="d-flex d-lg-none" onClick={showProHandler}><CloseIcon /></Button>
                                        </div>                                
                                        <hr className="mt-2 mt-lg-3 mb-0" />
                                        {
                                        apiresmsg ? (<div className="p-3 mt-2 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center fs-7"> {resstatus.spCheckoutData.message} </div>) : null
                                        }
                                        {
                                        resmsg ? <div className="p-3 mt-2 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center">{resstatus.spCheckoutData.message}</div> : null
                                        }
                                        <div className="mt-3 prolistscrollcover">
                                            <div className="prolistscroll">
                                                {
                                                    socketData && socketData.map((item) => {
                                                        return <LiveProductListItem key={item.product_id} id={item.product_id} name={item.product_name} price={item.product_price} imgSrc={item.product_image} variant_id={item.variant_id} qty={item.qty} option1={item.option1 ? item.option1 : ''} option2={item.option2 ? item.option2 : ''} option3={item.option3 ? item.option3 : ''} onClickHandler={showProDetailHandler} />
                                                    })
                                                }
                                            {/* <LiveProductListItem name="Pentry Midsommer Rose Top" price="$89.95" size="Small" color="Green" onClickHandler={showProDetailHandler} />
                                            <LiveProductListItem name="Pentry Midsommer Rose Top" price="$89.95" size="Small" color="Green" /> */}
                                            </div>
                                        </div>
                                        <div className="">
                                            <hr />
                                            <div className="d-flex justify-content-between">
                                                <div className="fs-7">Subtotal</div>
                                                <div className="fs-7 fw-bold">${Math.round(subtotal * 100) / 100}</div>
                                            </div>
                                            <div className="mt-3">
                                                <Button variant="primary" size="lg" className="w-100" onClick={showCheckoutHandler} disabled={socketData.length === 0  ? true : false} >Checkout</Button>
                                            </div>
                                        </div>

                                        {
                                            loading ? <Smallloader /> : null
                                        }
                                    </div>
                                   
                                </div>
                                 ) : ( 
                                    <CSSTransition in={showProDetail} timeout={300} classNames="slideup" unmountOnExit>
                                        <div className="livecartbox rounded-2">
                                            <div className="border rounded-2 h-100 position-relative d-flex flex-column overflow-auto">
                                                <LiveProductDetail varId={varId} qty={cartqty} closeHandler={closeProDetailHandler}  />
                                            </div>
                                        </div>                                          
                                    </CSSTransition>
                                 )
                                }
                            </CSSTransition>
                        )
                    }
                </div>
                <div className="d-flex d-lg-none position-absolute bottom-0 end-0 p-4 w-100 bottombtn">
                    <Button variant="primary" size="lg" className="w-100 mobilecartbtn"  onClick={showProHandler}>Your Cart <span>{totalQty}</span></Button>
                </div>            
            </Container>
            {
                show ? <LeaveSession show={show} sessionID={sessionID}  handleClose={handleClose}  /> : null
            }
            
            {/* {callnotcut ? <Loader /> : ''} */}
        </>
    )
}

export default ShopperLiveSession