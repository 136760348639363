import React, { useState } from "react";
import { ReactComponent as ArrowLeft } from '../../assets/images/arrow-left.svg';
import Summary from "../component/Summary";
import { Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeCheckout } from "../../services/checkoutSlice";
import CompletePayment from "../../component/Modals/CompletePayment";
import Smallloader from "../../component/SmallLoader";
import { endSession } from "../../services/shopperSlice";

const Shipping = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    let line_items = props.socketData.map((item) => {
        return ({"variant_id" :item.variant_id, "quantity":item.qty})
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [resmsg, setResmsg] = useState(false)
    const resstatus = useSelector(state => state.checkout)

    const handleSubmit = (values) => {
        //props.next(values)
        console.log("shipping values", values)
        const formData = {
            email:values.email,
            store_name:props.storeName,
            session_id:props.sessionID,
            first_name:values.first_name,
            last_name:values.last_name,
            city:values.city,
            state:values.province,
            country:values.country,
            address:values.address1,
            zip:values.zip,
            line_items : line_items
        }
        setLoading(true)
        props.showCallnotCut()
        dispatch(makeCheckout(formData))
        .then((res) => {
            //window.location.href = res.payload.data.checkout.web_url
            if(res.payload.status === 'success') {
                setResmsg(false)                
                dispatch(endSession({session_id: props.sessionID }))
                //window.location.href = res.payload.data.checkout.web_url
                navigate('/shopper/redirectpage')
                setLoading(false)
                //console.log("res.payload.data.web_url", res.payload.data.checkout.web_url)
                //setShow(true)
                //props.next(values)
            }                
            else {
                setResmsg(true)
                setLoading(false)
                setTimeout(() => {
                    setResmsg(false)
                },5000)
            }
            
        })
    }
    const shippingValidationSchema = Yup.object({
       // shippingmethod: Yup.string().required("please select Shipping Method"),
    })
    return(
        <>
            <div className="d-flex align-items-center">
                <h4 className="mb-0" onClick={() => props.prev(props.data)} role="button"> <ArrowLeft className="me-2" /> Return to Information</h4>
            </div>
            <hr className="mt-2 mt-lg-3 mb-6" />
                <Summary socketData={props.socketData} subtotal={props.subtotal} />
            <hr className="my-6 mt-lg-3" />
            {
            resstatus.error.length ? (<div className="p-3 mb-3 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center fs-7"> {resstatus.error} </div>) : null
            }
            {
            resmsg ? <div className="p-3 mb-3 bg-light bg-opacity-25 border border-danger text-danger rounded-2 text-center">{resstatus.spCheckoutData.message}</div> : null
            }
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <div className="fs-7 text-black-50">Contact</div>
                    <div><Link to="/" className="fs-7 text-black">{props.data.email}</Link></div>
                </div>
                <div><Button variant="link" className="px-1" onClick={() => props.prev(props.data)}>Change</Button></div>
            </div>
            <div className="d-flex justify-content-between mb-4">
                <div>
                    <div className="fs-7 text-black-50">Ship to</div>
                    <div className="fs-7 text-black">{props.data.address1} <br /> {props.data.city} {props.data.province} {props.data.zip}, {props.data.country}</div>
                </div>
                <div><Button variant="link" className="px-1" onClick={() => props.prev(props.data)}>Change</Button></div>
            </div>
            {/* <h4 className="fw-normal mb-3">Shipping Method</h4> */}

            <Formik validationSchema={shippingValidationSchema} initialValues={props.data} onSubmit={handleSubmit}>
            {(formprops) => (
                <Form>
                    {/* <div className="mb-2 border rounded-2 p-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-3" type="radio" name="shippingmethod" id="standard" value="Standard Shipping" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.shippingmethod === 'Standard Shipping'} />
                            <label className="form-check-label w-100" htmlFor="standard">
                                <span className="d-flex justify-content-between align-items-center fs-8">
                                    <span>Standard Shipping <br /> <span className="text-black-50">5-7 Business Days</span> </span>
                                    <span className="me-2">$7.99</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="mb-2 border rounded-2 p-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-3" type="radio" name="shippingmethod" id="usps" value="USPS Priority Mail" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.shippingmethod === 'USPS Priority Mail'} />
                            <label className="form-check-label w-100" htmlFor="usps">
                                <span className="d-flex justify-content-between align-items-center fs-8">
                                    <span>USPS Priority Mail <br /> <span className="text-black-50">2 Business Days</span> </span>
                                    <span className="me-2">$11.12</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="mb-2 border rounded-2 p-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-3" type="radio" name="shippingmethod" id="fedex" value="FedEx 2Day®" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.shippingmethod === 'FedEx 2Day®'} />
                            <label className="form-check-label w-100" htmlFor="fedex">
                                <span className="d-flex justify-content-between align-items-center fs-8">
                                    <span>FedEx 2Day® <br /> <span className="text-black-50">2 Business Days</span> </span>
                                    <span className="me-2">$19.02</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="mb-2 border rounded-2 p-2">
                        <div className="form-check ps-9">
                            <input className="form-check-input mt-3" type="radio" name="shippingmethod" id="fedexpriority" value="FedEx Priority Overnight®" onChange={formprops.handleChange} onBlur={formprops.handleBlur} checked={formprops.values.shippingmethod === 'FedEx Priority Overnight®'} />
                            <label className="form-check-label w-100" htmlFor="fedexpriority">
                                <span className="d-flex justify-content-between align-items-center fs-8">
                                    <span>FedEx Priority Overnight® <br /> <span className="text-black-50">1 Business Days</span> </span>
                                    <span className="me-2">$31.65</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="text-danger text-start d-block mt-2 w-100 fs-8"> 
                        {formprops.touched.shippingmethod && formprops.errors.shippingmethod ? (
                            <div>{formprops.errors.shippingmethod}</div>
                        ) : null}
                    </div> */}
                    <div className="mt-4">
                        <Button variant="primary" size="lg" className="w-100" type="submit">Continue to Payment</Button>
                    </div>
                    
                </Form>
            )}
            </Formik>
            
            {
                loading ? <Smallloader /> : null
            }
            {/* <CompletePayment show={show} handleShow={handleShow} handleClose={handleClose} resstatus={resstatus} /> */}
        </>
    )
}

export default Shipping