import React, {Suspense, lazy} from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Header  from "./Header";
import Protected from "./Protected";
import ShopperLiveSession from "../shopper/container/ShopperLiveSession";


const Home = lazy(() => import('./Home'))
const NotFound = lazy(() => import('./NotFound'))
const SignUp = lazy(() => import('../container/SignUp/SignUp'))
const PlanandPayment = lazy(() => import('../container/SignUp/PlanandPayment'))
const ConnectShopify = lazy(() => import('../container/ConnectShopify'))
const NoShopifySignUp = lazy(() => import('../container/SignUp/NoShopifySignUp'))
const Login = lazy(() => import('../container/Login'))
const RetailerLogin = lazy(() => import('../container/RetailerLogin'))
const ForgotPassword = lazy(() => import('../container/ForgotPassword'))
const ResetPassword = lazy(() => import('../container/ResetPassword'))
const TeamSignUp = lazy(() => import('../container/SignUp/TeamSignUp'))
const Dashboard = lazy(() => import('../container/Dashboard'))
const Availability = lazy(() => import('../container/Availability'))
const Sessions = lazy(() => import('../container/Sessions'))
const Team = lazy(() => import('../container/Team'))
const Support = lazy(() => import('../container/Support'))
const ProfileSettings = lazy(() => import('../container/ProfileSettings'))
const StartSession = lazy(() => import('../container/StartSession'))
const LiveSession = lazy(() => import('../container/LiveSession'))
const SessionRecap = lazy(() => import('../container/SessionRecap'))
// const ShopperLiveSession = lazy(() => import('../shopper/container/ShopperLiveSession'))
const ShopperSessionRecap = lazy(() => import('../shopper/container/ShopperSessionRecap'))
const RedirectPage = lazy(() => import('../shopper/container/RedirectPage'))
const Privacy = lazy(() => import('./Privacy'))
const Contact = lazy(() => import('./Contact'))
const StartSessionMsg = lazy(() => import('../container/StartSessionMsg'))
const CheckSessionMail = lazy(() => import('../container/CheckSessionMail'))


const Layout = () => {
    return (
        <>
            <div className="wrapper">
                <Header />   
                <Suspense fallback={<div className="align-self-center container text-center">Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/planandpayment" element={<Protected Component={PlanandPayment} />} />
                    <Route path="/connectshopify" element={<Protected Component={ConnectShopify} />} />
                    {/* <Route path="/noshopifysignup" element={<Protected Component={NoShopifySignUp} />} /> */}
                    <Route path="/noshopifysignup" element={<NoShopifySignUp />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/retailerlogin" element={<RetailerLogin />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/teamsignup" element={<Protected Component={TeamSignUp} />} />
                    <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
                    <Route path="/availability" element={<Protected Component={Availability} />} />
                    <Route path="/sessions" element={<Protected Component={Sessions} />} />
                    <Route path="/team" element={<Protected Component={Team} />} />
                    <Route path="/support" element={<Protected Component={Support} />} />
                    <Route path="/settings" element={<Protected Component={ProfileSettings} />} />
                    <Route path="/startsession" element={<Protected Component={StartSession} />} />
                    <Route path="/livesession" element={<Protected Component={LiveSession} />} />
                    <Route path="/sessionrecap" element={<SessionRecap />} />
                    <Route path="/shopper/shopperlivesession" element={<ShopperLiveSession />} />
                    <Route path="/shopper/shoppersessionrecap" element={<ShopperSessionRecap />} />
                    <Route path="/shopper/redirectpage" element={<RedirectPage />} />
                    <Route path="/*" element={<NotFound />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/sessionerror" element={<Protected Component={StartSessionMsg} />} />
                    <Route path="/checksessionmail" element={<Protected Component={CheckSessionMail} />} />
                </Routes>
                </Suspense>
            </div>
        </>
    )
}

export default Layout