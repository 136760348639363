import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { userDetails } from "../services/loginSlice";
import SessionRequest from "./Modals/SessionRequest";

//const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);
const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`, {
   forceNew: true,
   transports: ["polling"],
});

const ShowSessionRequest = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [resData, setResData] = useState()

    const [messageReceived, setMessageReceived] = useState("");

    const userData = useSelector(userDetails)
    const userId = userData && userData.id 
    

    useEffect(() => {
        socket.on("receive_message", (data) => {
            setMessageReceived(data.message);
        });
        socket.on(`send_request_${userId}`, (data) => { console.log(data.data)
            setResData(data.data)
            setMessageReceived(data.data);
            //console.log('messageReceived',messageReceived);
            localStorage.setItem('session_id', data.data.id)
            setShow(true)
            
        });
        socket.on(`cancel_request_${userId}`, (data) => { 
            //console.log(data.data)
            //setResData(data.data)
            //setMessageReceived(data.data);
            //console.log('messageReceived',messageReceived);
            localStorage.removeItem('session_id')
            setShow(false)            
        });
    }, [socket]);

    

    return (
        <>
            {resData && <SessionRequest show={show} handleClose={handleClose} resdata={resData} /> }
        </>
    )
}

export default ShowSessionRequest