import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    proData : '',
    proDetailData:'',
    addToCartData: '',
    removeCartData: ''
    
}

export const fetchProducts = createAsyncThunk(
    'fetchProducts/product',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/products`, data, {headers:headers})
        return response.data
    }
)

export const fetchProDetail = createAsyncThunk(
  'fetchProDetail/product',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }   
      const response = await axios.post(`/product-details`, data, {headers:headers})
      return response.data
  }
)

export const addToCart = createAsyncThunk(
  'addToCart/product',
  async (data) => {
      const hasAuth = JSON.parse(localStorage.getItem('token'))
      const headers = {
      'Content-Type': 'application/json',
      'Authorization' : `${hasAuth}`
      }   
      const response = await axios.post(`/add-to-cart`, data, {headers:headers})
      return response.data
  }
)

export const removeFromCart = createAsyncThunk(
  'removeFromCart/product',
  async (data) => {
      const headers = {
      'Content-Type': 'application/json',
      }
      const response = await axios.post(`/remove-from-cart`, data, {headers:headers})
      return response.data
  }
)

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchProducts.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = 'true'
            state.proData = action.payload
            state.error = ''
          })
          .addCase(fetchProducts.rejected, (state, action) => {
            state.status = 'false'
            state.proData = ''
            state.error = action.error.message
          })
          .addCase(fetchProDetail.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(fetchProDetail.fulfilled, (state, action) => {
            state.status = 'true'
            state.proDetailData = action.payload
            state.error = ''
          })
          .addCase(fetchProDetail.rejected, (state, action) => {
            state.status = 'false'
            state.proDetailData = ''
            state.error = action.error.message
          })
          .addCase(addToCart.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(addToCart.fulfilled, (state, action) => {
            state.status = 'true'
            state.addToCartData = action.payload
            state.error = ''
          })
          .addCase(addToCart.rejected, (state, action) => {
            state.status = 'false'
            state.addToCartData = ''
            state.error = action.error.message
          }) 
          .addCase(removeFromCart.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(removeFromCart.fulfilled, (state, action) => {
            state.status = 'true'
            state.removeCartData = action.payload
            state.error = ''
          })
          .addCase(removeFromCart.rejected, (state, action) => {
            state.status = 'false'
            state.removeCartData = ''
            state.error = action.error.message
          })       
    },
})
export default productSlice.reducer