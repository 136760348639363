import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    spavailData: {},
    addspavailData: {},
    delspavailData: {}
}

export const getSpAvail = createAsyncThunk(
    'getSpAvail/specialAvailability',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        } 
        const response = await axios.get(`/user/sp_availability`, {headers:headers})
        return response.data
    }
)

export const addSpAvail = createAsyncThunk(
    'addSpAvail/addSpAvail',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        } 
        const response = await axios.post(`/user/special-availability`, data, {headers:headers})
        return response.data
    }
)

export const deleteSpAvail = createAsyncThunk(
    'deleteSpAvail/addSpAvail',
    async (id) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        } 
        const response = await axios.get(`/user/delete-availability/${id}`, {headers:headers})
        return response.data
    }
)

export const specialAvailabilitySlice = createSlice({
    name:'specialAvailability',
    initialState,
    reducers:{

    },
    extraReducers: (builder) => {
        builder
          .addCase(getSpAvail.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(getSpAvail.fulfilled, (state, action) => {
            state.status = 'true'
            state.spavailData = action.payload
            state.error = ''
          })
          .addCase(getSpAvail.rejected, (state, action) => {
            state.status = 'false'
            state.spavailData = {}
            state.error = action.error.message
          })
          .addCase(addSpAvail.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(addSpAvail.fulfilled, (state, action) => {
            state.status = 'true'
            state.addspavailData = action.payload
            state.error = ''
          })
          .addCase(addSpAvail.rejected, (state, action) => {
            state.status = 'false'
            state.addspavailData = {}
            state.error = action.error.message
          })
          .addCase(deleteSpAvail.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(deleteSpAvail.fulfilled, (state, action) => {
            state.status = 'true'
            state.delspavailData = action.payload
            state.error = ''
          })
          .addCase(deleteSpAvail.rejected, (state, action) => {
            state.status = 'false'
            state.delspavailData = {}
            state.error = action.error.message
          })
    }
})

export const getAllSpAvail = (state) => state.specialAvailability.spavailData
export default specialAvailabilitySlice.reducer