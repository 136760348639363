import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    paymentData: '',
    error:''
}

export const makePayment = createAsyncThunk(
    'makePayment/payment',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
            'Content-Type': 'application/json',
            'Authorization' : `${hasAuth}`
        } 
        const res = await axios.post(`/payment`, data, {headers:headers})
        return res.data
    }
)


export const paymentSlice = createSlice({
    name:'payment',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(makePayment.pending, (state) => {
            state.status = 'loading'
            state.error = ''
        })
        .addCase(makePayment.fulfilled, (state, action) => {
            state.status = 'true'
            state.paymentData = action.payload
            state.error = ''
        })
        .addCase(makePayment.rejected, (state, action) => {
            state.status = 'false'
            state.paymentData = ''
            state.error = action.error.message
        })
    }
})

export default paymentSlice.reducer