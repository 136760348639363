import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg'
import mapimg from '../../assets/images/mapimg.png'

const ConfirmOrder = (props) => {
   
    return(
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Order Confirmation</h4>
                <Button variant="Link" className="p-1"><CloseIcon /></Button>
            </div>
            <hr className="mt-2 mt-lg-3 mb-6" />
            <div className="fs-7 text-black-50 mb-2">Order #12345</div>
            <h4 className="fw-normal">Thank you Ashley!</h4>
            <div className="border rounded-2 mb-3">
                <div><img src={mapimg} alt="map" className="w-100 img-fluid" /></div>
                <div className="p-3">
                    <div className="fs-7 mb-2">Your order is confirmed</div>
                    <div className="fs-8 text-black-50">We’ve accepted your order, and we’re getting it ready. A confirmation email has been sent to {props.data.email}. Come back to this page for updates on your order status.</div>
                </div>
            </div>
            <div className="border rounded-2 p-3">
                <div className="fs-7 mb-3">Customer Information</div>
                <div className="d-flex justify-content-between">
                    <div className="fs-9">
                        <div className="mb-2">Shipping Address</div>
                        <div className="mb-2 text-black-50">{props.data.first_name} { props.data.last_name} <br /> {props.data.address1} <br /> {props.data.city} {props.data.province} {props.data.zip} <br /> {props.data.country} <br /> {props.data.phone}</div>
                        <div className="mb-2">Shipping Method</div>
                        <div className="mb-2 text-black-50">{props.data.shippingmethod}</div>
                    </div>
                    <div className="fs-9">
                        <div className="mb-2">Billing Address</div>
                        <div className="mb-2 text-black-50">{props.data.first_name} { props.data.last_name} <br /> {props.data.address1} <br /> {props.data.city} {props.data.province} {props.data.zip} <br /> {props.data.country} <br /> {props.data.phone}</div>
                        <div className="mb-2">Payment Method</div>
                        <div className="mb-2 text-black-50">Visa ending with 4242 <br /> <span className="text-black">$102.96</span></div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                {/* <Link to="/shopper/shoppersessionrecap" className="w-100 btn btn-primary btn-lg">Continue Shopping</Link> */}
                <Button onClick={props.prev} variant="primary" size="lg" className="w-100">Continue Shopping</Button>
            </div>
        </>
    )
}

export default ConfirmOrder