import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import signupReducer from '../services/signupSlice';
import loginReducer from '../services/loginSlice';
import passwordReducer from '../services/passwordSlice';
import teamsignupReducer from '../services/teamsignupSlice';
import teamListReducer from '../services/teamListSlice';
import ConnectShopifyReducer from '../services/connectShopifySlice';
import availabilityReducer from '../services/availabilitySlice';
import supportReducer from '../services/supportSlice';
import specialAvailabilityReducer from '../services/specialAvailabilitySlice';
import paymentReducer from '../services/paymentSlice';
import changeaccountstatusReducer from '../services/changeaccountstatusSlice';
import sessionReducer from '../services/sessionSlice';
import shopperReducer from '../services/shopperSlice';
import sessionlistReducer from '../services/sessionlistSlice';
import profileReducer from '../services/profileSlice';
import productReducer from '../services/productSlice';
import dashboardReducer from '../services/dashboardSlice';
import checkoutReducer from '../services/checkoutSlice';
import checkSessionReducer from '../services/checkSessionSlice';

const persistConfig = {
  key: 'livelyretailer',
  version: 1,
  storage,
  blacklist: ['shopper']
}

const appReducer = combineReducers({ 
  signup: signupReducer,
  login: loginReducer,
  forgotpassword: passwordReducer,
  teamsignup: teamsignupReducer,
  teamlist: teamListReducer,
  ConnectShopify: ConnectShopifyReducer,
  availability: availabilityReducer,
  support: supportReducer,
  specialAvailability: specialAvailabilityReducer,
  payment:paymentReducer,
  changeaccountstatus: changeaccountstatusReducer,
  session: sessionReducer,
  shopper: shopperReducer,
  sessionlist: sessionlistReducer,
  profile: profileReducer,
  product:productReducer,
  dashboard: dashboardReducer,
  checkout: checkoutReducer,
  checksession: checkSessionReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'login/logout') {
    state = undefined;
    persistConfig.storage.removeItem('persist:livelyretailer')
    localStorage.clear()
    console.log('if', state)
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store)
console.log('intial state', store.getState())