import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    supportData : {},
    contactData : {}
}

export const makeSupport = createAsyncThunk(
    'makeSupport/support',
    async (data) => {
        const response = await axios.post(`/support`, data)
        return response.data
    }
)

export const makeContact = createAsyncThunk(
  'makeContact/support',
  async (data) => {
      const response = await axios.post(`/contact`, data)
      return response.data
  }
)

export const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
        builder
          .addCase(makeSupport.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeSupport.fulfilled, (state, action) => {
            state.status = 'true'
            state.supportData = action.payload
            state.error = ''
          })
          .addCase(makeSupport.rejected, (state, action) => {
            state.status = 'false'
            state.supportData = {}
            state.error = action.error.message
          })
          .addCase(makeContact.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeContact.fulfilled, (state, action) => {
            state.status = 'true'
            state.contactData = action.payload
            state.error = ''
          })
          .addCase(makeContact.rejected, (state, action) => {
            state.status = 'false'
            state.contactData = {}
            state.error = action.error.message
          })
            
    },
})
export default supportSlice.reducer